import ExcelJS from "exceljs";
import { SwalAlert } from "../components/dialog/swal_alert";

interface Column {
    label: string;
    field: string;
    width?: number;
}

interface MappedColumn {
    header: string;
    key: string;
    width: number;
}
class ExcelService {
    private workbook: ExcelJS.Workbook;

    constructor() {
        this.workbook = new ExcelJS.Workbook();
    }

    async addWorksheet(sheetName: string, columns: { header: string, key: string, width: number }[], data: any[]) {
        try {
            const worksheet = await this.workbook.addWorksheet(sheetName);
            worksheet.columns = columns;
            data.forEach((item) => worksheet.addRow(item));
            worksheet.getRow(1).font = { bold: true };
        } catch (error) {
            SwalAlert({ title: 'ไม่สามารถออกรายงานได้', description: 'กรุณาทำรายการใหม่อีกครั้งในภายหลัง' })
        }
    }

    mapColumns = (columns: Column[]): MappedColumn[] => {
        return columns.map((column) => ({
            header: column.label,
            key: column.field,
            width: column.width ?? 15,
        }));
    };

    async generateExcelBuffer() {
        return await this.workbook.xlsx.writeBuffer();
    }

    cleanup() {
        this.workbook = new ExcelJS.Workbook(); // Reinitialize workbook to free resources
    }
}

const excelService = new ExcelService();

export default excelService;
