import React, { useState } from "react";
import {
    Paper,
    Stack,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Button,
    useTheme,
} from "@mui/material";
import { StyledTextButton } from "../../styled/filled_button";
import PromptTypo from "../typography/typography_prompt";
import OrderTableAgentRateComponent from "./order_table_agent_rate";
import { sxCenter } from "../styled/styled_flex_container";
import '../../App.css'
import '../table/ripple.css'

const rxOrderInputPattern = /^[0-9+\-*/._= ]*$/;
interface RowData {
    id: number;
    type: string;
    number: string;
    amount: string;
}
const AgentOrderTableComponent: React.FC = () => {
    const [rows, setRows] = useState<RowData[]>([
        { id: 1, type: "บ", number: "", amount: "" },
        { id: 2, type: "บ", number: "", amount: "" },
        { id: 3, type: "บ", number: "", amount: "" },
        { id: 4, type: "บ", number: "", amount: "" },
        { id: 5, type: "บ", number: "", amount: "" },
        { id: 6, type: "บ", number: "", amount: "" },
        { id: 7, type: "บ", number: "", amount: "" },
        { id: 8, type: "บ", number: "", amount: "" },
        { id: 9, type: "บ", number: "", amount: "" },
        { id: 10, type: "บ", number: "", amount: "" },
    ]);

    // Function to handle input changes
    function handleInputChange(id: number,
        field: keyof RowData,
        value: string) {
        setRows((prevRows) => prevRows.map((row) => row.id === id ? { ...row, [field]: value } : row
        ));
    }

    // Function to add 5 new rows
    function addRows(): void {
        setRows((prevRows) => {
            const newRows: RowData[] = [];
            const startId = prevRows.length + 1; // Determine the starting ID for new rows
            for (let i = 0; i < 5; i++) {
                newRows.push({ id: startId + i, type: "บ", number: "", amount: "" });
            }
            return [...prevRows, ...newRows];
        });
    }


    const toggleType = (id: number) => {
        setRows((prevRows) =>
            prevRows.map((row) => {
                if (row.id === id) {
                    return { ...row, type: row.type === "บ" ? "ล" : row.type === "ล" ? "บ+ล" : "บ" };
                }
                return row;
            })
        );
    };

    const muiTheme = useTheme();

    return (
        <Paper sx={{ padding: 2 }}>
            <Stack
                direction={{ sm: 'column', md: 'row' }} // Stack direction changes based on screen size
                spacing={2}
            >
                <Box
                    sx={{
                        maxWidth: { sm: '100%', md: '300px' },
                        width: { sm: '100%', md: '35%' }, // Full width for small screens, 30% for large screens
                        flexShrink: 0,
                        maxHeight: '80vh'
                    }}
                >
                    <OrderTableAgentRateComponent />
                </Box>

                <Box
                    sx={{
                        maxWidth: { sm: '80vw', md: '80vw' },
                        width: { xs: '50%', lg: '35%' }, // Full width for small screens, 35% for large screens
                        flexShrink: 0,
                        overflow: 'not'
                    }}
                >
                    <TableContainer component={Paper} sx={{ marginTop: 2, maxHeight: '80vh', minWidth: '48vw' }}>
                        <Table stickyHeader size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ width: '50px' }}>ลำดับ</TableCell>
                                    <TableCell sx={{ width: '100px' }}>ประเภท</TableCell>
                                    <TableCell sx={{ width: '200px' }}>เลข</TableCell>
                                    <TableCell sx={{ width: '200px' }}>จำนวน</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell>{row.id}</TableCell>
                                        <TableCell sx={sxCenter}>
                                            <StyledTextButton
                                                sx={{
                                                    ":focus": { color: muiTheme.palette.secondary.light },
                                                    ":hover": {},
                                                }}
                                                onClick={() => toggleType(row.id)}
                                            >
                                                <PromptTypo variant="h6">{row.type}</PromptTypo>
                                            </StyledTextButton>
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                sx={{ minWidth: '100px' }}
                                                autoComplete="off"
                                                variant="outlined"
                                                size="small"
                                                value={row.number}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    const isValid = rxOrderInputPattern.test(value);
                                                    isValid && handleInputChange(row.id, "number", e.target.value);
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                sx={{ minWidth: '100px' }}
                                                autoComplete="off"
                                                variant="outlined"
                                                size="small"
                                                value={row.amount}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    const isValid = rxOrderInputPattern.test(value);
                                                    isValid && handleInputChange(row.id, "amount", e.target.value);
                                                }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <Button
                            sx={{ margin: '10px 24px' }}
                            fullWidth
                            variant="contained"
                            color="secondary"
                            onClick={addRows}
                        >
                            เพิ่มแถว
                        </Button>
                    </TableContainer>
                </Box>

                {/* Add another Table if needed for 3 sections on large screens */}
                {/** 
         * You can include another section here if you want the third column on large screens.
         */}
            </Stack>
        </Paper>
    );
};

export default AgentOrderTableComponent;
