import React, { useState } from 'react';
import { Box, Stack } from '@mui/material';
import { DateRange } from '@mui/icons-material';
import dayjs from 'dayjs';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the styles for DatePicker
import { StyledOutlineDatepickerButton } from '../../styled/filled_button';
import Spacer from '../spacing/spacer';
import PromptTypo from '../typography/typography_prompt';
import { generalDateFormat } from '../../configs/constants';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { th } from 'react-day-picker/locale';

interface SingleDatePickerProps {
    label?: string;
    onChange?: (date: Date | string | null) => void;
}

export default function SingleDatePicker(props: SingleDatePickerProps) {
    dayjs.extend(utc);
    dayjs.extend(timezone);

    const { label } = props;
    const [startDate, setStartDate] = useState<Date | string | null>(null);

    return (
        <Box>
            <PromptTypo sx={{ fontSize: '10px' }} textAlign={'left'}>
                {label}
            </PromptTypo>
            <Spacer size="xs" />
            <DatePicker
                locale={th}
                showTimeInput={false}
                selected={startDate ? dayjs(startDate).tz().toDate() : null}
                onChange={(date) => {
                    props.onChange?.(dayjs(date).toDate());
                    setStartDate(dayjs(date).tz().format());
                }}
                withPortal
                customInput={
                    <StyledOutlineDatepickerButton>
                        <Spacer size="xs" />
                        <Stack direction={'row'} alignItems={'center'}>
                            <DateRange />
                            <Spacer size="md" />
                            <PromptTypo>
                                {startDate
                                    ? dayjs(startDate).format(generalDateFormat)
                                    : `เลือก${label}`}
                            </PromptTypo>
                            <Spacer size="xs" />
                        </Stack>
                    </StyledOutlineDatepickerButton>
                }
            />
        </Box>
    );
};

