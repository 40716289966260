import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { useAppSettings } from '../stores/context/app_setting_context';

export default function CustomizedInputBase({ onClick = () => { }, placeholder = '', ...props }) {
    const { state } = useAppSettings();
    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent form submission
            props.onSubmit?.(event); // Trigger the provided onSubmit handler, if any
        }
    };

    return (
        <Paper
            component="form"
            sx={{
                p: '2px 4px',
                display: 'flex',
                alignItems: 'center',
                width: 250,
                outline: `0.5px solid transparent`,
                border: `0.5px solid transparent`,
                transition: 'outline 0.3s ease, border 0.3s ease',
                '&:hover': {
                    outline: `0.3px solid ${state.theme.palette.primary.main}`,
                    border: `0.3px solid ${state.theme.palette.primary.main}`,
                },
                '&:focus': {
                    outline: `0.3px solid ${state.theme.palette.primary.main}`,
                    border: `0.3px solid ${state.theme.palette.primary.main}`,
                },
                ...props.sx
            }}
            onSubmit={(e) => e.preventDefault()}
            onBlur={props.onBlur?.()}
        >
            <InputBase
                defaultValue={props.value}
                onClick={onClick}
                sx={{ ml: 1, flex: 1 }}
                placeholder={placeholder}
                inputProps={{ 'aria-label': placeholder }}
                onChange={(event) => props.onChange?.(event.target.value)}
                onKeyDown={handleKeyDown} // Handle Enter key press
            />
            {props.searchIcon && (
                <IconButton
                    disabled={!props.searchable}
                    type="button"
                    sx={{ p: '10px' }}
                    aria-label="search"
                >
                    <SearchIcon />
                </IconButton>
            )}
            {props.customIcon && <IconButton
                disabled={!props.searchable}
                type="button"
                sx={{ p: '10px' }}
                aria-label="search"
            >
                <>{props.customIcon}</>
            </IconButton>}
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
        </Paper>
    );
}
