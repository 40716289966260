import React, { useEffect, useState } from 'react'
import { Column, DataRow } from '../components/table/hoverable_table_row';
import { Box, Button, MenuItem, Select, SelectChangeEvent, Stack } from '@mui/material';
import { FormatLineSpacingRounded, ManageSearchRounded } from "@mui/icons-material";
import PromptTypo from '../components/typography/typography_prompt';
import { SPACING_MD, User, tableContentConfig } from '../configs/constants';
import { Col, Row, StyledContainer } from '../components/styled/styled_flex_container';
import CreditTableFilter, { CREDIT_STATUS, CreditRequestFilterOptions, OWNER_STATUS } from '../components/table_filters/credit_table_filter';
import { StyledOutlineButton } from '../styled/filled_button';
import creditService, { CreditBalanceData, CreditStatusFilter, CreditStatusType, CreditTransactionRequestOptions, CreditTransactionResponseData, CreditTxnData, OwnerStatusTypeFilter, ResponseWithMeta } from '../services/credit_service';
import { useDialog } from '../stores/providers/dialog_provider';
import CreditAdjustmentDialog, { CreditAdjustmentDialogProps } from '../components/dialog/credit_adjustment_dialog';
import { useNavigate, useParams } from 'react-router-dom';
import { formatToCurrencyString } from '../helpers/number_formatter_helper';
import { JSX } from 'react/jsx-runtime';
import PaginatedTable from '../components/template/pagination_table_page';
import AppPagination, { AppPaginationMetaData } from '../components/pagination/app_pagination';
import Spacer from '../components/spacing/spacer';
import AppReactSelector, { SelectorItem } from '../components/form/react_selector';
import dayjs from 'dayjs';
import { convertToRelative } from '../helpers/date_time_helper';
import StyledSelect from '../styled/styled_select';
import FormBuilder, { AppFormData } from '../components/form/form_builder';
import { SwalCompleted } from '../components/dialog/swal_alert';
import { count } from 'console';

interface TopUpRequestPageProps { }

const columns: Column[] = [
    { label: 'เลขที่', field: 'id', scale: 0.4 },
    { label: 'ชื่อ Owner', field: 'fullName', highlighten: true, scale: 1.4 },
    { label: 'Total Balance', field: 'totalBalance', scale: 1.4, ...tableContentConfig.commonPrimary, align: 'right' },
    { label: 'สถานะ', field: 'ownerStatus', scale: 1 },
    {
        label: 'คำขอล่าสุด', field: 'lastUpdateDatetime', highlighten: true, scale: 1.4,
        render: (field) => <>{convertToRelative(field)}</>
    },
    { label: '', field: 'actions', align: 'right', scale: 0.2 },
];

const CreditBalanceListPage = ({ }: TopUpRequestPageProps) => {
    const [data, setData] = useState<any[]>([]);
    const [page, setPage] = useState(1);
    const [ownerStatus, setOwnerStatus] = useState<OwnerStatusTypeFilter | string | undefined | null>(OWNER_STATUS[0].value);
    const navigate = useNavigate();
    const { openDialog, closeDialog } = useDialog();
    const [dialogId, setDialogId] = useState<number | null>(null);
    const [params, setParams] = useState<any>({ page: 1, perPage: 10, count: 0 });

    useEffect(() => {
        const { page } = params;
        fetchTableData(page);
    }, [params.page, ownerStatus])

    const fetchTableData = async ({ page = 1 }) => {
        const { page: pageIndex, perPage } = params;
        const ownerStatusParam = ownerStatus === 'all' ? null : ownerStatus ?? ''
        const response = await creditService.listCreditsBalance({ page: page ?? pageIndex, perPage, ownerStatus: ownerStatusParam as string });
        if (!!response) {
            const responseData = response?.data ?? [] as CreditBalanceData[];
            await _dataWithActions(responseData as CreditBalanceData[]).then((res) => {
                setData(res)
                setParams({ ...params, page: response.meta?.page ?? 1, count: response.meta?.totalPages ?? 1 });
            });
        }
        else { return null }
    }

    const _dataWithActions = async (data: CreditBalanceData[]) => {
        if (!!data && data?.length == 0) return [];
        const dataWithAdditionalColumns = await Promise.all(data!.map(async (item) => {
            const dataDecorate = {
                ...item,
                fullName: `${item.ownerFirstname}  ${item.ownerLastname}`,
                totalBalance: formatToCurrencyString(item?.balance ?? 0),
                actions: _getTableActions(item)
            }
            return dataDecorate;
        }));

        return dataWithAdditionalColumns;
    }

    const requestAdjustment = async (formData: Record<string, string | number>) => {
        const response = await creditService.creditAdjustRequest({
            ownerId: +formData.ownerId,
            type: "ADJUST",
            amount: formData.amount,
            requestDatetime: dayjs().toISOString(),
            requestRemark: `${formData.reason}`,
            bankAccountId: "",
            transferDatetime: dayjs().toISOString(),
        });
        if (response) {
            SwalCompleted().then(() => navigate('/credit-approval/adjustments'))

        }
    }

    const handleToDetail = (item: CreditBalanceData) => {
        navigate(`/credit-txn/detail/${item.ownerId}`);
    }

    const fetchData = (params: { page: number; perPage: number }) => {
        return creditService.listCreditsBalance({
            page: params.page,
            perPage: params.perPage,
        });
    }

    function _getTableActions(item: CreditBalanceData) {
        return <Stack direction={'row'} width='100%' justifyContent='flex-end' sx={{}}>
            <StyledOutlineButton
                onClick={() => { handleToDetail(item) }}>
                <Stack direction='row'>
                    <Button
                        variant="text"
                        sx={{ textDecoration: 'none' }} // Removes any default link underlines
                    >
                        ดูรายระเอียด
                        <ManageSearchRounded sx={{ margin: '0px 8px' }} />
                    </Button>

                </Stack>
            </StyledOutlineButton>
            <Box sx={{ width: '16px' }} />
            <StyledOutlineButton onClick={() => {
                const dialogId = openDialog(<CreditDetailAdjustmentDialog item={item} onSubmit={requestAdjustment} />);
                setDialogId(dialogId);
            }}
            >
                <Stack direction='row'>
                    <PromptTypo variant='body1'>
                        จัดการเครดิต
                    </PromptTypo>
                    <FormatLineSpacingRounded sx={{ margin: '0px 8px' }} />
                </Stack>
            </StyledOutlineButton>
        </Stack >
    }

    function handleOnPageChange(page: number) {
        setParams({ page });
    }
    function handleChange(event: SelectChangeEvent<any>) {
        setOwnerStatus(event.target.value);
    }

    return (
        <StyledContainer>
            <Col style={{ padding: 0 }}>
                <Row centerY style={{ justifyContent: 'space-between' }}>
                    <PromptTypo variant="h5">รายการเครดิต</PromptTypo>
                </Row>
                <Spacer size='lg' />
                <Col>
                    <StyledSelect
                        label="สถานะ"
                        value={ownerStatus as OwnerStatusTypeFilter}
                        options={OWNER_STATUS}
                        sx={{ minWidth: '170px', marginRight: SPACING_MD }}
                        onChange={(value) => {
                            setOwnerStatus(value ?? OWNER_STATUS[0].value)
                        }} />
                    <Spacer size='sm' />
                </Col>
                <PaginatedTable
                    count={params.count}
                    showPagination={false}
                    columns={columns}
                    stickyHeader={false}
                    data={data as unknown as DataRow[] || []} />
                <AppPagination
                    page={page}
                    count={params.count}
                    onPageChange={handleOnPageChange} />

            </Col>
        </StyledContainer>
    )
}

const formFields: AppFormData[] = [
    {
        name: 'type', value: 'ADJUST', required: true, placeholder: 'ประเภท', label: 'ประเภท', inputType: 'select',
        choices: [
            { value: 'ADJUST', label: 'Adjust' },
        ],
        textFieldProps: { type: 'number', name: '', autoComplete: '', "aria-readonly": true }
    },
    {
        name: 'amount', value: '', required: true, placeholder: 'จำนวน', label: 'จำนวน',
        textFieldProps: { type: 'number', name: '', autoComplete: '' }
    },
    {
        name: 'reason', value: '', placeholder: 'หมายเหตุ', label: 'หมายเหตุ',
        textFieldProps: { type: 'text', name: '', autoComplete: '' }
    },

];

const CreditDetailAdjustmentDialog: React.FC<CreditAdjustmentDialogProps> = ({ item, onSubmit }) => {
    const { closeDialog } = useDialog();
    const handleFormSubmit = (data: Record<string, string | number>) => {
        onSubmit?.({ ...data, ...item });
    };
    return (
        <FormBuilder
            formTitle="จัดการเครดิต"
            onCancel={() => {
                closeDialog();
            }
            }
            containerSize={560}
            fields={formFields}
            onSubmit={(data) => {
                closeDialog();
                handleFormSubmit({ ...data, ...item });
            }} />
    );
};

export default CreditBalanceListPage
