import React from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    useTheme,
    Box,
    Stack,
    Alert,
    AlertTitle,
    TextField,
} from "@mui/material";
import StyledSelect from "../../styled/styled_select";
import AppReactSelector from "../form/react_selector";
import PromptTypo from "../typography/typography_prompt";
import { sxTypoHeader6 } from "../../configs/text_styles";
import Spacer from "../spacing/spacer";
import OdmntSearchSelectorComponent, { OdmntOption } from "../odmnt_selector/odmnt_selector";
import OdmntSelectSearch from "../odmnt_selector/odmnt_selector";
import { MOCK_AGENT_NAME_LIST } from "../../configs/mock";
import dayjs from "dayjs";
import { StyledTextButton } from "../../styled/filled_button";

const MOCK_AGENT_OPTIONS = [
    { label: 'นายสมชาย ใจดี', value: 'somchai' },
    { label: 'นางสาวสมฤดี สุขสันต์', value: 'somrudee' },
    { label: 'นายก้องภพ สงวนศักดิ์', value: 'kongpop' },
    { label: 'นางสาวชลธิชา บัวงาม', value: 'chonthicha' },
    { label: 'นายอาทิตย์ ทองคำ', value: 'arthit' },
    { label: 'นางสาวพิมพ์ใจ บุญส่ง', value: 'pimjai' },
    { label: 'นายเอกชัย ทรัพย์สมบูรณ์', value: 'ekachai' },
    { label: 'นางสาววิไลพร จันทร์เพ็ญ', value: 'wilaiporn' },
    { label: 'นายธนพล กิจเจริญ', value: 'thanapol' },
    { label: 'นางสาวชุติมา พูนทรัพย์', value: 'chutima' },
];

const OrderTableAgentRateComponent: React.FC = () => {
    const data = [
        { type: "3 บน", pay: "500.00", discount: "90.00" },
        { type: "3 โต๊ด", pay: "100.00", discount: "25.00" },
        { type: "2 บน", pay: "70.00", discount: "25.00" },
        { type: "2 โต๊ด", pay: "0.00", discount: "0.00" },
        { type: "วิ่ง บน", pay: "3.00", discount: "10.00" },
        { type: "3 ล่าง", pay: "100.00", discount: "25.00" },
        { type: "2 ล่าง", pay: "70.00", discount: "25.00" },
        { type: "วิ่ง ล่าง", pay: "4.00", discount: "10.00" },
    ];

    const muiTheme = useTheme();

    return (
        <TableContainer component={Paper} style={{ marginTop: "16px" }}
            sx={{
                bgcolor: `${muiTheme.palette.background.default}`,
                borderRadius: "4px",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
                overflow: "auto",
                maxHeight: '80vh',
                minWidth: '300px'
            }}>
            <Alert style={{ display: 'flex', alignItems: 'center' }}
                variant="filled" severity="error">
                <AlertTitle> <PromptTypo variant='h5'>
                    หวยรัฐบาล - {dayjs().format('DD-MM-YYYY')}
                </PromptTypo></AlertTitle>
                <PromptTypo variant='body1'>
                    สถานะ :ปิดรับแล้ว
                </PromptTypo>
            </Alert>
            <Stack alignItems={'center'} direction={'row'} sx={{ padding: 2 }}>
                <PromptTypo variant="h6"
                    sx={sxTypoHeader6}>สมาชิก</PromptTypo>
                <Spacer size="xl" />
                <Box sx={{ width: '100%' }}>
                    <StyledTextButton disabled value={'AGENT_DISPLAY_NAME'} >
                        <PromptTypo variant="subtitle1">
                            AGENT_DISPLAY_NAME
                        </PromptTypo>
                    </StyledTextButton>
                </Box>
            </Stack>
            <Box sx={{ padding: 2 }}>
                <Typography
                    variant="h6"
                    sx={sxTypoHeader6}
                >
                    ราคาจ่าย
                </Typography>
            </Box>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ fontWeight: "bold" }}>ชนิด</TableCell>
                        <TableCell align="right" style={{ fontWeight: "bold" }}>
                            จ่าย
                        </TableCell>
                        <TableCell align="right" style={{ fontWeight: "bold" }}>
                            ลด (%)
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell>{row.type}</TableCell>
                            <TableCell align="right">{row.pay}</TableCell>
                            <TableCell align="right">{row.discount}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Spacer size="lg" />
            <Paper sx={{ padding: { lg: 5, md: 1, sm: 0 } }}>
                <PromptTypo sx={{ width: '300px' }} variant="h6">
                    <span>
                        ยอดโพยใบนี้
                    </span>
                    <span> = </span>
                    <span style={{ color: muiTheme.palette.secondary.light }}>
                        98,765.00 บาท
                    </span>
                </PromptTypo>
            </Paper>
        </TableContainer >
    );
};

export default OrderTableAgentRateComponent;
