import React, { createContext, useContext, useState } from 'react';
import { Snackbar } from '@mui/material';

interface SnackbarContextType {
    show: (message: string) => void;
}

const SnackbarContext = createContext<SnackbarContextType | undefined>(undefined);

export const SnackbarProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState<string>('');

    const show = (message: string) => {
        setMessage(message);
        setOpen(true);

        setTimeout(() => {
            setOpen(false);
        }, 2000);
    };

    return (
        <SnackbarContext.Provider value={{ show }}>
            {children}
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                open={open}
                autoHideDuration={2000}
                message={message}
            />
        </SnackbarContext.Provider>
    );
};

export const useSnackbar = (): SnackbarContextType => {
    const context = useContext(SnackbarContext);
    if (!context) {
        throw new Error('useSnackbar must be used within a SnackbarProvider');
    }
    return context;
};
