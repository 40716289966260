import React, { useEffect, useRef, useState } from 'react';
import Select, { components, MultiValue, SingleValue } from 'react-select';
import makeAnimated from 'react-select/animated';
import { Typography, useTheme } from '@mui/material';
import PromptTypo from '../typography/typography_prompt';
import { useAppSettings } from '../../stores/context/app_setting_context';
import { SPACING_MD, SPACING_SM, SPACING_XS } from '../../configs/constants';

export interface SelectorItem {
    label: string;
    value: string | number | null | undefined;
}

interface AppReactSelectorProps {
    defaultValue?: any;
    options?: SelectorItem[];
    isMulti?: boolean;
    placeholder?: string;
    width?: string;
    label?: string;
    onChange?: (selector: SelectorItem | string) => void;
}

const AppReactSelector: React.FC<AppReactSelectorProps> = ({
    options = [],
    isMulti = false,
    defaultValue,
    width,
    placeholder,
    label,
    onChange
}) => {
    const { state } = useAppSettings();
    const textRef = useRef<HTMLSpanElement | null>(null);
    const muiTheme = useTheme();
    const [selected, setSelected] = useState<SelectorItem | SelectorItem[] | null>(
        defaultValue || null
    );
    const [placeholderState, setPlaceholderState] = useState<string | undefined>(placeholder);

    const animatedComponents = makeAnimated();

    useEffect(() => { setSelected(defaultValue) }, [defaultValue]);

    const handleChange = (
        newValue: any
    ) => {
        onChange?.(newValue);
        if (isMulti) {
            setSelected(newValue as SelectorItem[]);
        } else {
            setSelected(newValue as SelectorItem);
        }
        setPlaceholderState(undefined);
    };

    const CustomPlaceholder = (props: any) => (
        <components.Placeholder {...props}>
            <PromptTypo color="textSecondary">{props.children}</PromptTypo>
        </components.Placeholder>
    );

    const CustomSingleValue = (props: any) => (
        <components.SingleValue {...props}>
            <PromptTypo>{displayValue()}</PromptTypo>
        </components.SingleValue>
    );

    const CustomOption = (props: any) => (
        <components.Option {...props}>
            <PromptTypo>{props.data.label}</PromptTypo>
        </components.Option>
    );

    const customTheme = (defaultTheme: any) => ({
        ...defaultTheme,
        colors: {
            ...defaultTheme.colors,
            primary: state.theme.palette.primary || muiTheme.palette.primary.main,
            primary75: `${muiTheme.palette.primary.main}75`,
            primary50: `${muiTheme.palette.primary.main}50`,
            primary25: `${muiTheme.palette.primary.main}25`,
            neutral0: muiTheme.palette.background.paper,
            neutral5: muiTheme.palette.background.default,
            neutral10: muiTheme.palette.background.default,
            neutral20: muiTheme.palette.divider,
            neutral30: muiTheme.palette.divider,
            neutral40: muiTheme.palette.text.secondary,
            neutral50: muiTheme.palette.text.disabled,
            neutral60: muiTheme.palette.text.secondary,
            neutral70: muiTheme.palette.text.primary,
            neutral80: muiTheme.palette.text.primary,
            neutral90: muiTheme.palette.text.primary,
            danger: muiTheme.palette.error.main,
            dangerLight: muiTheme.palette.error.light,
        },
    });

    const customStyles = {
        container: (provided: any) => ({
            ...provided,
            width: width ? `${width}` : 'auto',
            minWidth: '200px',
        }),
    };

    const displayValue = () => {
        return (Array.isArray(selected)
            ? selected.map((item) => item.label).join(', ')
            : selected?.label || '') ?? ''
    }
    return (
        <>
            <span
                ref={textRef}
                style={{
                    visibility: 'hidden',
                    position: 'absolute',
                    whiteSpace: 'nowrap',
                }}
            >
                {displayValue()}
            </span>
            {label && <PromptTypo sx={{ marginBottom: SPACING_XS, fontSize: '10px' }}>{label}</PromptTypo>}
            <Select
                isClearable={false}
                placeholder={placeholder ?? `เลือก ${label ?? ''}`}
                hideSelectedOptions={true}
                theme={customTheme}
                styles={customStyles}
                closeMenuOnSelect={!isMulti}
                components={{
                    ...animatedComponents,
                    // Placeholder: CustomPlaceholder,
                    SingleValue: CustomSingleValue,
                    Option: CustomOption,
                }}
                isMulti={isMulti}
                value={selected}
                onChange={handleChange}
                options={options}
                defaultValue={defaultValue}
            />
        </>
    );
};

export default AppReactSelector;
