export interface PaginationRequestParams<T> {
    page?: number;
    perPage?: number;
    [key: string]: any;
}

export interface ResponseMetaData {
    totalPages?: number;
    totalCount?: number;
    page?: number;
    limit?: number;
    perPage?: number;
}

export const defaultPaginationParams: PaginationRequestParams<{}> = {
    page: 1,
    perPage: 10,
};
export const defaultResponseMetaData: ResponseMetaData = {
    page: 1,
    totalPages: 1
};
