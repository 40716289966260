import React, { useEffect, useState } from 'react';

import ReusableTable, { Column } from '../components/table/hoverable_table_row';
import userService from "../services/user_service"
import { Col, Row, StyledContainer } from "../components/styled/styled_flex_container";
import PromptTypo from "../components/typography/typography_prompt";
import { Alert, Button, IconButton, MenuItem, Pagination } from "@mui/material";
import CreateUserForm from "../components/dialog/create_user_form";
import { Add, Delete, Edit, Lock, Style } from "@mui/icons-material";
import { useDialog } from "../stores/providers/dialog_provider";
import { MOCK_PERF_KEY, User, USER_ROLE, USER_ROLE_NAME } from "../configs/constants";
import RoleSelector, { RoleOption } from "../components/users/role_selector";
import MenuPopup from '../components/menu_popup/menu_popup';
import { useAuth } from '../stores/providers/auth_provider';
import { Roles } from '../models/role';
import PermissionAssignmentForm from '../components/dialog/permission_form';
import DialogService from '../components/dialog/confirmation_by_sweet_alert2';
import { createdUserfail, createdUserSuccessfully, SwalAlert, SwalCompleted } from '../components/dialog/swal_alert';
import Swal from 'sweetalert2';
import { StyledFillButton } from '../styled/filled_button';
import dayjs from 'dayjs';
import AppPagination from '../components/pagination/app_pagination';
import { convertISOStringtoFormattedDateTime } from '../helpers/date_time_helper';
import PaginatedTable from '../components/template/pagination_table_page';
import Spacer from '../components/spacing/spacer';
import { totalmem } from 'os';
import { useSnackbar } from '../stores/providers/snackbar_provider';


export const defaultFilterRoleOptions: RoleOption[] = [
    { value: USER_ROLE.SUPER_ADMIN, name: USER_ROLE_NAME[USER_ROLE.SUPER_ADMIN], visible: true, disabled: true, selected: false, requiredPermission: 'USER_LIST_OWNER' },
    { value: USER_ROLE.OWNER, name: USER_ROLE_NAME[USER_ROLE.OWNER], visible: true, disabled: false, selected: true, requiredPermission: 'USER_LIST_OWNER' },
    { value: USER_ROLE.STAFF, name: USER_ROLE_NAME[USER_ROLE.STAFF], visible: true, disabled: false, selected: true, requiredPermission: 'USER_LIST_STAFF' },
    { value: USER_ROLE.AGENT, name: USER_ROLE_NAME[USER_ROLE.AGENT], visible: true, disabled: false, selected: true, requiredPermission: 'USER_LIST_AGENT' }
];

const columns: Column[] = [
    { label: 'รหัสสมาชิก', field: 'code' },
    { label: 'ชื่อผู้ใช้', field: 'username' },
    {

        label: 'ชื่อ/นามสกุล',
        field: 'fullName',
        highlighten: true
    },
    {
        label: 'ระดับ', field: 'roleName',

    },
    { label: 'E-mail', field: 'email' },
    { label: 'สถานะ', field: 'status' },
    { label: 'หมายเหตุ', field: 'note' },
    { label: 'สร้างเมื่อ', field: 'createdAt' },
    {
        label: '', field: 'actions',
    },
];

const UsersPage: React.FC = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
    const [roleOptions, setRoleOptions] = useState<RoleOption[]>(defaultFilterRoleOptions);
    const [page, setPage] = useState<number>(1);
    const [listMetadata, setListMetadata]: [any, any] = useState(null);
    const { openDialog, closeDialog } = useDialog();
    const [dialogId, setDialogId] = useState<number | null>(null);
    const { roles: defaulPermissionByRole, user: currentUser } = useAuth();
    const { show } = useSnackbar();

    useEffect(() => {
        const fetchAvailableRoles = () => {
            const checkAvailableRole = defaultFilterRoleOptions.map(role => {
                const canView = role.requiredPermission ? currentUser.permissions.includes(role.requiredPermission) : false;
                const availableFilterRoles: RoleOption = {
                    ...role,
                    visible: canView,
                    selected: !!canView,
                }

                return availableFilterRoles;
            });
            const defaultFetchingRoles = checkAvailableRole
                .filter(role => role.visible === true);

            return defaultFetchingRoles;
        };
        const newPermissionByRole = fetchAvailableRoles();
        setRoleOptions(newPermissionByRole);
        setSelectedRoles(newPermissionByRole.map(role => role.value));
    }, [currentUser.permissions]);

    useEffect(() => {
        if (selectedRoles.length > 0) {
            listUser({ page: page });
        }
    }, [selectedRoles, page]);

    async function listUser({ page = 1 }) {
        const ownerId = currentUser.ownerId;
        const payload = { roles: selectedRoles, ownerId, page, perPage: 10 }
        if (ownerId == 0) { delete payload.ownerId }
        const users = await userService.listUser(payload) as { meta: Record<string, any>, data: User[] };
        const userList = users?.data ?? []
        const metaData = users.meta ?? {}

        setUsers(userList);
        setListMetadata(metaData);
        const usersWithActions = userList?.map((user: User) => ({
            ...user,
            actions: _getUserRowActions(user),
            role: defaulPermissionByRole.find((role: Roles) => role?.id === user?.roleId)?.name ?? '',
            createdAt: convertISOStringtoFormattedDateTime(user.createdAt),

        })) as User[];
        if (users?.meta && users?.meta?.page !== page) setPage(users.meta.page);
        setRoleOptions(roleOptions);
        setUsers(usersWithActions);
    }

    const handlePageChange = (newPage: number) => {
        setListMetadata({ ...listMetadata, page: newPage });
        setPage(newPage);
    };

    async function handleOnCreateUser(formData: Record<string, string | number>) {
        onClose();
        const newUser = { ...formData };

        try {
            const result = await userService.createUser(newUser);
            if (result) {
                await listUser({ page: 1 });
                SwalCompleted();
            }
        } catch (error: any) {
            createdUserfail(error.response.data.message);
        }

    }

    async function updateUser(userId: number, formData: User) {
        console.log({ userId, formData })
        try {
            await userService.updateUser(userId, formData).then(async (res: boolean | null) => {
                if (res) {
                    await listUser({ page: listMetadata?.page || 1 }).then(() => {
                        SwalCompleted();
                    })
                }
                // !!res && await listUser({ page: listMetadata?.page || 1 }).then(() => SwalCompleted())
            });
        } catch (error: any) {
            console.log(error);
        }
    }

    const handleOnEditUser = (newValue: string[], user: User) => {
        const newFormData = { 'permissions': newValue };
        closeDialog(dialogRefId);
        const options = DialogService.confirmation(
            "ยืนยันการแก้ไขข้อมูล",
            `คุณแน่ใจที่จะแก้ไขข้อมูลผู้ใช้ ${user.username} หรือไม่ ?`
        );
        DialogService.open(options, () => {
            closeDialog(dialogId);
            updateUser(+user.id, { ...user, permissions: newValue });
        });

    }

    const handleEdit = (row: any) => {
        const dialogId = openDialog(<CreateUserForm user={row} onSubmit={(formData) => {
            const options = DialogService.confirmation(
                "ยืนยันการแก้ข้อมูล",
                `คุณแน่ใจที่จะแก้ไขผู้ใช้ ${row.username} หรือไม่ ?`
            );
            closeDialog(dialogId);
            DialogService.open(options, () => {
                updateUser(+row.id, { ...row, ...formData });
            });
        }
        }
        />);
        setDialogId(dialogId);
    };

    const handleDelete = async (user: User) => {
        const options = DialogService.confirmation("ยืนยันการลบข้อมูล", `คุณแน่ใจที่จะลบข้อมูลผู้ใช้ ${user.username} หรือไม่ ?`);

        DialogService.open(options, async () => {
            try {
                const result = await userService.deleteUser((+user.id));
                if (result) {
                    await listUser({ page: 1 });
                    SwalCompleted();
                }
            } catch (error) {
                SwalAlert({ title: `${error}` })
            }
        })
    };

    function handleOnOpenCreateDialog() {
        const dialogId = openDialog(<CreateUserForm onSubmit={handleOnCreateUser} />);
        setDialogId(dialogId);
    }

    const getDefaultPermissionsFromSelectedUser = async (user?: User | null): Promise<string[]> => {
        if (!user) return [];
        const defaultRoles = await userService.getRoleById(user.roleId);
        return defaultRoles?.permissions ?? [];
    }
    let dialogRefId: number;
    const handleAssignPermission = async (selectedUser: User) => {
        const selectedUserInfo = await userService.getUserById(selectedUser.id);
        const defaultPermissions = await getDefaultPermissionsFromSelectedUser(selectedUser as User | null);
        dialogRefId = openDialog(
            <PermissionAssignmentForm
                onClose={onClose}
                dialogRef={dialogRefId}
                onSubmit={(newValue) => {
                    handleOnEditUser(newValue, (selectedUser));
                }}
                onChanged={(newValue: string[]) => { }}
                selectedUser={selectedUserInfo as User | undefined}
                defaultPermission={defaultPermissions}
            />
        );
        setDialogId(dialogRefId);
    };

    const onClose = () => {
        closeDialog(dialogId);
        setDialogId(null);
    }

    const _getUserRowActions = (user: User) => {
        const userRoleName = USER_ROLE_NAME[user.roleCode].toUpperCase();
        const editUserRequiredPermission = `USER_EDIT_${userRoleName}`;
        const delteUserRequiredPermission = `USER_DELETE_${userRoleName}`;
        const editableUser = currentUser.permissions.includes(editUserRequiredPermission);
        const deleteableUser = currentUser.permissions.includes(delteUserRequiredPermission);
        const editableUserPermission = currentUser.permissions.includes(`USER_PERMISSION_${userRoleName}`); // TODO: ยังไม่ได้ทำ

        return <MenuPopup>
            <MenuItem key={'edit'}
                onClick={() => {
                    handleEdit(user);
                }}
                disabled={!editableUser} >
                <IconButton color="primary" aria-label="edit">
                    <Edit />
                </IconButton>
                <PromptTypo>แก้ไขข้อมูล</PromptTypo>
            </MenuItem>

            <MenuItem key={'permission'}
                onClick={() => handleAssignPermission(user)}
                disabled={!editableUserPermission} >
                <IconButton color="info" aria-label="permission">
                    <Lock />
                </IconButton>
                <PromptTypo>แก้ไขสิทธิ์</PromptTypo>
            </MenuItem>
            <MenuItem key={'delete'}
                onClick={() => handleDelete(user)}
                disabled={!deleteableUser}>
                <IconButton color="error" aria-label="delete">
                    <Delete />
                </IconButton>
                <PromptTypo>ลบ</PromptTypo>
            </MenuItem>
        </MenuPopup>
    }

    const handleChipRoleChange = (newRoles: string[]) => {
        setSelectedRoles(newRoles);
        setPage(1);
    };

    const hasCreatePermission = currentUser.permissions.some(permission =>
        /USER_CREATE/.test(permission)
    );

    return (
        <StyledContainer>
            <Col>
                <Row style={{ justifyContent: 'space-between' }}>
                    <PromptTypo variant="h5">รายการผู้ใช้งาน</PromptTypo>
                    {hasCreatePermission &&
                        <div>
                            <Button style={{ padding: 8 }}
                                onClick={() => handleOnOpenCreateDialog()}>
                                <Add /> เพิ่มรายการ
                            </Button>
                        </div>
                    }
                </Row>
                <div style={{ width: 10, height: 10 }}></div>
                <Spacer size='sm' />
                <RoleSelector
                    value={selectedRoles}
                    filterRoles={roleOptions?.filter((role: RoleOption) => role.visible)}
                    handleFilterRoleChange={handleChipRoleChange}
                />
                <Spacer size='lg' />
                <PaginatedTable
                    showPagination={false}
                    columns={columns}
                    data={users ?? []}
                />
                <AppPagination
                    page={listMetadata?.page ?? 1}
                    count={+ (listMetadata?.totalPages ?? 0)}
                    onPageChange={handlePageChange} />

            </Col>
        </StyledContainer>
    );
}

export default UsersPage;
