import React from 'react';
import {
  Select as BaseSelect,
  SelectProps,
  selectClasses,
  SelectRootSlotProps,
} from '@mui/base/Select';
import { Option as BaseOption, optionClasses } from '@mui/base/Option';
import { styled, SxProps, Theme } from '@mui/system';
import UnfoldMoreRoundedIcon from '@mui/icons-material/UnfoldMoreRounded';
import PromptTypo from '../components/typography/typography_prompt';
import { SPACING_XS } from '../configs/constants';

interface customActionTypes {
  label: string,
  value: string
}

interface StyledSelectProps {
  options: customActionTypes[],
  value: string,
  label?: string,
  onChange: (value: string | null) => void;
  sx?: SxProps<Theme>;
}

export default function StyledSelect({ options, value, onChange, sx, label }: StyledSelectProps) {
  return (
    <div>
      {label && <PromptTypo sx={{ marginBottom: SPACING_XS, fontSize: '10px' }}>{label}</PromptTypo>}
      <Select value={value} onChange={(_, newValue) => onChange(newValue)} sx={sx}>
        {options.map((option) => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
    </div>
  );
}

function Select(props: SelectProps<string, false> & { sx?: SxProps<Theme> }) {
  const slots: SelectProps<string, false>['slots'] = {
    root: StyledButton,
    listbox: Listbox,
    popup: Popup,
    ...props.slots,
  };

  return (
    <BaseSelect
      {...props}
      slots={slots}
      slotProps={{
        root: {
          ...props.slotProps?.root,
          sx: props.sx,
        } as any,
      }}
    />
  );
}

const CustomButton = React.forwardRef<HTMLButtonElement, SelectRootSlotProps<number, false>>(
  function CustomButton(props, ref) {
    const { ownerState, ...other } = props;
    return (
      <button
        type="button"
        {...other}
        ref={ref}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          cursor: 'pointer'
        }}
      >
        <span>{other.children}</span>
        <UnfoldMoreRoundedIcon />
      </button>
    );
  }
);

export const StyledButton = styled(CustomButton, { shouldForwardProp: () => true })(
  ({ theme }) => `
    height: 37px;
    border-radius: 4px;
    text-align: left;
    line-height: 1.5;
    background: transparent;
    color: ${theme.palette.primary.main};
    border: 2px solid ${theme.palette.primary.main};
    font-weight: bold;
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);

    &:hover {
      background-color: ${theme.palette.action.hover};
      border-color: ${theme.palette.secondary.main};
      color: ${theme.palette.primary.contrastText};
    }

    &:active {
      background-color: ${theme.palette.action.selected};
      border-color: ${theme.palette.primary.main};
    }

    &.${selectClasses.focusVisible} {
      outline: 0;
      border-color: ${theme.palette.secondary.main};
      box-shadow: 0 0 0 3px ${theme.palette.action.selected};
    }

    &.${selectClasses.disabled} {
      border-color: #b3b3b3;
      color: #b3b3b3;
    }

    & > svg {
      font-size: 1rem;
      vertical-align: middle;
    }
  `
);

const Listbox = styled('ul')(
  ({ theme }) => `
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    box-sizing: border-box;
    padding: 6px;
    margin: 12px 0;
    min-width: 320px;
    border-radius: 12px;
    overflow: auto;
    outline: 0;
    background: ${theme.palette.mode === 'dark' ? '#1C2025' : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? '#434D5B' : '#DAE2ED'};
    color: ${theme.palette.mode === 'dark' ? '#E5EAF2' : '#303740'};
    box-shadow: 0 2px 6px ${theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.50)' : 'rgba(0,0,0, 0.05)'};
  `
);

export const Option = styled(BaseOption)(
  ({ theme }) => `
    list-style: none;
    padding: 8px;
    border-radius: 8px;
    cursor: default;

    &:last-of-type {
      border-bottom: none;
    }

    &.${optionClasses.selected} {
      background-color: ${theme.palette.mode === 'dark' ? '#003A75' : '#DAECFF'};
      color: ${theme.palette.mode === 'dark' ? '#DAECFF' : '#003A75'};
    }

    &.${optionClasses.highlighted} {
      background-color: ${theme.palette.mode === 'dark' ? '#303740' : '#E5EAF2'};
      color: ${theme.palette.mode === 'dark' ? '#DAE2ED' : '#303740'};
    }

    &.${optionClasses.highlighted}.${optionClasses.selected} {
      background-color: ${theme.palette.mode === 'dark' ? '#003A75' : '#DAECFF'};
      color: ${theme.palette.mode === 'dark' ? '#DAECFF' : '#003A75'};
    }

    &:focus-visible {
      outline: 3px solid ${theme.palette.mode === 'dark' ? '#0072E5' : '#99CCF3'};
    }

    &.${optionClasses.disabled} {
      color: ${theme.palette.mode === 'dark' ? '#6B7A90' : '#B0B8C4'};
    }

    &:hover:not(.${optionClasses.disabled}) {
      background-color: ${theme.palette.mode === 'dark' ? '#303740' : '#F3F6F9'};
      color: ${theme.palette.mode === 'dark' ? '#DAE2ED' : '#303740'};
    }
  `
);

const Popup = styled('div')`
  z-index: 1;
`;

const Paragraph = styled('p')(
  ({ theme }) => `
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    margin: 10px 0;
    color: ${theme.palette.mode === 'dark' ? '#B0B8C4' : '#6B7A90'};
  `
);
