import { de } from "react-day-picker/locale";
import baseAxios from "./axios_instance";
import { removeEmptyParam } from "./helper";
import { PaginationRequestParams, ResponseMetaData } from "./type";
import { useAuth } from "../stores/providers/auth_provider";
import { STORAGE_KEY } from "../configs/constants";

type ResponseWithMeta<T> = {
    data?: AgentRateDetails[] | T[] | null;
    meta?: ResponseMetaData;
    message?: string;
}

export interface AgentRateDetails {
    id?: number;
    prefixKey?: string;
    fieldDisplayName?: string;
    agentId?: number | string | null | undefined;
    agentCode?: number | string | null | undefined;
    agentFirstname?: string;
    agentLastname?: string;
    threeUpperStraightPrice?: number;
    threeUpperStraightPercent?: number;
    threeShufflePrice?: number;
    threeShufflePercent?: number;
    twoUpperStraightPrice?: number;
    twoUpperStraightPercent?: number;
    twoShufflePrice?: number;
    twoShufflePercent?: number;
    upperRunningPrice?: number;
    upperRunningPercent?: number;
    threeLowerStraightPrice?: number;
    threeLowerStraightPercent?: number;
    twoLowerStraightPrice?: number;
    twoLowerStraightPercent?: number;
    lowerRunningPrice?: number;
    lowerRunningPercent?: number;
    fourFloatingPrice?: number;
    fourFloatingPercent?: number;
    fiveFloatingPrice?: number;
    fiveFloatingPercent?: number;
}

export interface AgentCodeListRequestParams {
    name?: string;
    agentCode?: string;
}

class RateService {
    async getUserToken() {
        return localStorage.getItem(STORAGE_KEY.ACCESS_TOKEN);
    }
    async getHeaders() {
        return { Authorization: `Bearer ${await this.getUserToken()}` }
    }

    async listOwnerRates(ownerId: number, params: PaginationRequestParams<AgentCodeListRequestParams>) {
        const paramsWithoutEmpty = removeEmptyParam<PaginationRequestParams<AgentCodeListRequestParams>>
            ({ ...params });
        try {
            const response = await baseAxios.get<ResponseWithMeta<AgentRateDetails>>
                (`/owners/${ownerId}/rates`,
                    {
                        params: paramsWithoutEmpty,
                        headers: await this.getHeaders()
                    });

            return response.data;
        } catch (error) {
            console.log('Error fetching owners:', error);
            return { data: [] };
        }
    };

    async updateOwnerRates(ownerId: number, agentId: number, payload: AgentRateDetails) {
        const tempPayload = JSON.parse(JSON.stringify(payload));
        delete tempPayload.agentFirstname;
        delete tempPayload.agentLastname;
        delete tempPayload.agentId;
        delete tempPayload.agentCode;
        delete tempPayload.id;

        const payloadKey = Object.keys(tempPayload);
        payloadKey.map((key: string) => {
            if (!!!tempPayload[key]) tempPayload[key] = 0;
        })

        try {
            const response = await baseAxios.put<ResponseWithMeta<AgentRateDetails>>
                (`/owners/${ownerId}/rates/${agentId}`, tempPayload);
            return response.data;
        } catch (error) {
            console.log('Error fetching owners:', error);
            return { message: error };
        }
    };
}

const rateService = new RateService();
export default rateService;

