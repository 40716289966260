import dayjs from "dayjs";
import { utcDateFotmatted } from "../helpers/date_time_helper";
import { generalDateFormat } from "../configs/constants";

export function removeEmptyParam<T extends Record<string, any>>(params: T): T {
    Object.keys(params).forEach((key) => {
        const value = params[key];
        if (value === undefined || value === null || value === '') {
            delete params[key];
        }
    });
    return setUtcDateToParams(params);
}

export function setUtcDateToParams<T extends Record<string, any>>(params: T): T {
    const newParams: Partial<T> = { ...params };

    Object.keys(params).forEach((key) => {
        if (timeFormatKeys.includes(key)) {
            const value = params[key];
            if (typeof value === 'string' || value instanceof Date) {
                const date = new Date(value);
                if (!isNaN(date.getTime())) {
                    date.setHours(date.getHours() + 7);
                    newParams[key as keyof T] = date.toISOString() as T[keyof T];
                }
            }
        }
    });

    return newParams as T; // Cast back to T to match the return type.
}

const timeFormatKeys = [
    'dateTransfer',
    'dateRequest',
    'dateRequestFrom',
    'dateRequestTo',
]