import React, { useEffect } from 'react';
import { SpeedDial, SpeedDialAction, SpeedDialIcon, SxProps } from '@mui/material';
import { Menu } from '@mui/icons-material';
import { ReactNode } from 'react';
import Alignment from '../alignment';

// Define the interface for the action objects that will be passed as props
export interface DialAction {
    icon?: ReactNode;
    name?: string;
    bgColor?: string;
    onClick?: (item: any) => void;
}

interface AppSpeedDealProps {
    actions?: DialAction[];
    isOpen?: boolean;
    onToggleOpen?: (open: boolean) => void;
    sx?: SxProps;
}

function AppSpeedDeal({
    actions = [],
    isOpen = false,
    onToggleOpen,
    sx
}: AppSpeedDealProps) {
    const [open, setOpen] = React.useState(isOpen);

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    const handleOpen = () => {
        setOpen(true);
        onToggleOpen?.(true);
    };

    const handleClose = () => {
        setOpen(false);
        onToggleOpen?.(false); // Notify parent that the SpeedDial is closed
    };

    return (
        <Alignment position='center'>
            <SpeedDial
                direction='left'
                ariaLabel="SpeedDial basic example"
                sx={{ ...sx, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                icon={<Menu />}
                open={true}
                onClose={handleClose}
                onOpen={handleOpen}
                hidden
            >
                {actions.map((action, index) => (
                    <SpeedDialAction
                        disableHoverListener
                        sx={{
                            color: 'white',
                            backgroundColor: `${action.bgColor}75`,
                            ":hover": { backgroundColor: `${action.bgColor}99` }
                        }}
                        key={index}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        onClick={action.onClick}
                    />
                ))}
            </SpeedDial>
        </Alignment>
    );
}

export default AppSpeedDeal;
