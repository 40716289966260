import { Box, Input, Paper, Stack, TextField, useTheme, Link } from '@mui/material'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import Spacer from '../components/spacing/spacer'
import { StyledContainer } from '../components/styled/styled_flex_container'
import PromptTypo from '../components/typography/typography_prompt'
import { defaultPaginationParams, defaultResponseMetaData, PaginationRequestParams, ResponseMetaData } from '../services/type'
import { useAppSettings } from '../stores/context/app_setting_context'
import { StyledFillButton, StyledOutlineButton, StyledTextButton } from '../styled/filled_button'
import PaginatedTable from '../components/template/pagination_table_page'
import { Column, DataRow } from '../components/table/hoverable_table_row'
import { gameStateDisplay, tableContentConfig } from '../configs/constants'
import GameCreateForm from '../components/dialog/game_create_form'
import { useDialog } from '../stores/providers/dialog_provider'
import StyledTextFieldWithErrorText from '../components/styled/styled_textfield'
import gameService, { GameSession } from '../services/game_service'
import { SwalCompleted } from '../components/dialog/swal_alert'
import { convertISOStringtoFormattedDateTime, formatDateToUTCOrLocal } from '../helpers/date_time_helper'
import { useNavigate } from 'react-router-dom'

const DATE_FORMAT = 'DD-MM-YYYY';

const GameManagementPage: React.FC = () => {
    const { state } = useAppSettings();
    const navigate = useNavigate();
    const { openDialog, closeDialog } = useDialog();
    const [dialogId, setDialogId] = useState<any>(null);
    const [tableData, setTableData] = useState<GameSession[]>();
    const [meta, setMeta] = useState<ResponseMetaData>(defaultResponseMetaData);
    const [gameDate, setGameDate] = useState<string | undefined>('');
    const [filter, setFilter] = useState<PaginationRequestParams<{}>>(defaultPaginationParams);

    useEffect(() => {
        fetchData();
    }, [])


    const formatDate = (value: string): string => {
        let cleanedValue = value.replace(/\D/g, '');
        if (cleanedValue.length > 2) {
            cleanedValue = cleanedValue.slice(0, 2) + '-' + cleanedValue.slice(2);
        }
        if (cleanedValue.length > 5) {
            cleanedValue = cleanedValue.slice(0, 5) + '-' + cleanedValue.slice(5);
        }

        return cleanedValue.slice(0, 10);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setGameDate(formatDate(value));
    };
    const handleBlur = () => {
        const isValidDate = dayjs(gameDate, DATE_FORMAT).isValid();
        if (gameDate) {
            const newDateValue = isValidDate ? gameDate : ''
            setGameDate(newDateValue);
        }
    };

    const handleSearch = () => {
        if (filter.page !== 1) {
            setFilter((prevFilter) => ({ ...prevFilter, page: 1 }));
        } else {
            fetchData();
        }
    };

    const fetchData = async ({ page = 1, withSwalComp = false } = filter) => {
        try {
            const response = await gameService.listGameSession({
                ...filter,
                startGameDate: dayjs(gameDate).isValid() ? dayjs(gameDate, 'DD-MM-YYYY').utc(true) : null,
                page,
            });

            const data = response?.data?.map((e) => (
                { ...e, actions: { id: e.id } }
            )) ?? [];

            setTableData(data);
            setMeta(response?.meta ?? {})
            if (withSwalComp) {
                SwalCompleted();
            }
        } catch (error) {
            console.error("Error fetching rates:", error);
        }
    }


    const handleCreateGame = () => {
        const dialogId = openDialog(<Paper sx={{ paddingLeft: '42px' }}>
            <GameCreateForm closeDialog={closeDialog} />
        </Paper>, 'sm');
        setDialogId(dialogId);
    };

    const theme = useTheme();
    const { paper: bgPaper, default: bgDefault } = theme.palette.background;
    const textColor = theme.palette.text.secondary;
    const secondaryColor = theme.palette.secondary.main;

    const columns: Column[] = [
        { field: 'startGameDate', label: 'งวด', ...tableContentConfig.common, render: (value: string) => dayjs(value, 'YYYY-MM-DD').format(DATE_FORMAT) },
        {
            field: 'state', label: 'สถานะ', ...tableContentConfig.common, highlighten: true, render: (value: string) => {
                return `${(gameStateDisplay as any)?.[value] ?? value}`
            }
        },
        {
            field: 'endGameActualDatetime', label: 'ปิดเมื่อ', ...tableContentConfig.common,
            render: (value: string) => <PromptTypo>{convertISOStringtoFormattedDateTime(value)}</PromptTypo>
        },

        {
            field: 'actions', label: 'ตัวเลือก', ...tableContentConfig.common,
            render: (value: any) => <StyledFillButton sx={{ color: theme.palette.common.white, bgcolor: secondaryColor, ":hover": { color: theme.palette.secondary.light } }}
                onClick={() => { navigate(`/game-management/${value.id}`) }}>ดูรายละเอียด</StyledFillButton>
        },
    ];
    return (
        <StyledContainer>
            <PromptTypo variant="h5">การจัดการงวด</PromptTypo>
            <Spacer size='md' />
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <Stack direction={'row'} alignItems={'center'}>
                    <PromptTypo sx={{ width: 70 }}>ค้นหางวด</PromptTypo>
                    <Spacer size='sm' />
                    <Input
                        sx={{ height: '35px', bgcolor: bgPaper, padding: '10px 10px', color: textColor }}
                        placeholder={DATE_FORMAT}
                        value={gameDate ?? ''}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        error={false}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                handleSearch();
                                event.preventDefault();
                            }
                        }}

                    />
                    <Spacer size='md' />
                    <StyledOutlineButton
                        disabled={false}
                        onClick={handleSearch}>
                        <PromptTypo>ค้นหา</PromptTypo>
                    </StyledOutlineButton>
                </Stack>
                <StyledFillButton
                    sx={{ bgcolor: `${state.theme.palette.action.selected}`, ":hover": { color: theme.palette.secondary.light } }}
                    onClick={() => handleCreateGame()}>
                    เปิดงวดใหม่
                </StyledFillButton>
            </Stack>
            <Spacer size='md' />
            <PaginatedTable
                stickyActions={true}
                stickyHeader={false}
                showPagination={false}
                columns={columns}
                data={tableData as unknown as DataRow[] || []} />
        </StyledContainer>
    );
};

export default GameManagementPage;
