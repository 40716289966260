import { FolderOff } from "@mui/icons-material";
import { Box, Paper, Stack } from "@mui/material";
import PromptTypo from "../typography/typography_prompt";
import Alignment from "../alignment";

export const EmptyComponent = ({ displayedText = 'ไม่พบข้อมูล' }: { displayedText?: string }) => {
    return (
        <Box sx={{ height: 640 }}>
            <Alignment position="center">
                <Stack direction='column' justifyContent='center' alignItems='center'>
                    <FolderOff style={{ fontSize: 76, color: '#999' }} />
                    <PromptTypo variant="h6" style={{ marginTop: 16 }}>
                        {displayedText}
                    </PromptTypo>
                </Stack>
            </Alignment>
        </Box>
    );
};
