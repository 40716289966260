import React, { createContext, useContext, useEffect, useState, useCallback, ReactNode } from 'react';

// Define types for the context
interface KeyListener {
    key: string;
    callback: (event: KeyboardEvent) => void;
}

interface KeyListenerContextProps {
    addKeyListener: (key: string, callback: (event: KeyboardEvent) => void) => void;
    removeKeyListener: (key: string, callback: (event: KeyboardEvent) => void) => void;
}

// Create the Context
const KeyListenerContext = createContext<KeyListenerContextProps | undefined>(undefined);

// Provider Props
interface KeyListenerProviderProps {
    children: ReactNode;
}

// Provider Component
export const KeyListenerProvider: React.FC<KeyListenerProviderProps> = ({ children }) => {
    const [listeners, setListeners] = useState<KeyListener[]>([]);

    const addKeyListener = useCallback((key: string, callback: (event: KeyboardEvent) => void) => {
        setListeners((prevListeners) => [...prevListeners, { key, callback }]);
    }, []);

    const removeKeyListener = useCallback((key: string, callback: (event: KeyboardEvent) => void) => {
        setListeners((prevListeners) =>
            prevListeners.filter((listener) => listener.key !== key || listener.callback !== callback)
        );
    }, []);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            listeners.forEach(({ key, callback }) => {
                if (event.key === key) {
                    event.preventDefault();
                    callback(event);
                }
            });
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [listeners]);

    return (
        <KeyListenerContext.Provider value={{ addKeyListener, removeKeyListener }}>
            {children}
        </KeyListenerContext.Provider>
    );
};

export const useKeyListener = (): KeyListenerContextProps | null => {
    const context = useContext(KeyListenerContext);
    return context ?? null;
};
