import { Check, Close } from '@mui/icons-material';
import { Divider } from '@mui/material';
import dayjs from 'dayjs';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Alignment from '../components/alignment';
import ExcelGenerator from '../components/csv_exporter/xlxs_download_button';
import CustomDateRangePicker from '../components/datepicker/date_range_picker';
import DialogService from '../components/dialog/confirmation_by_sweet_alert2';
import { SwalCompleted } from '../components/dialog/swal_alert';
import { AppFormData } from '../components/form/form_builder';
import AppPagination from '../components/pagination/app_pagination';
import Spacer from '../components/spacing/spacer';
import AppFloatingSpeedDeal from "../components/speed_deal/speed_deal";
import { StyledContainer } from '../components/styled/styled_flex_container';
import { DataRow } from '../components/table/hoverable_table_row';
import PaginatedTable from '../components/template/pagination_table_page';
import PromptTypo from '../components/typography/typography_prompt';
import { convertISOStringtoFormattedDateTime } from '../helpers/date_time_helper';
import { formatToCurrencyString } from '../helpers/number_formatter_helper';
import creditService from '../services/credit_service';
import { ResponseMetaData } from '../services/type';
import { useAppSettings } from '../stores/context/app_setting_context';
import { useDialog } from '../stores/providers/dialog_provider';
import { statusTableDisplayMap, tableContentConfig } from '../configs/constants';
import { renderNumberWithCurrencyString, renderValueIfNotEmpty } from '../configs/functions';

export interface Column {
    id?: string;
    label: string;
    field: string;
    align?: 'left' | 'right' | 'center';
    highlighten?: boolean;
    render?: (props: any) => React.ReactNode;
    format?: (value: number) => string;
    scale?: number
    width?: number
    minWidth?: number;
    group?: string;
}

export default function CreditAdjustmentApprovalListPage() {
    const [data, setData] = useState<any[]>([]);
    const { openDialog, closeDialog } = useDialog();
    const [meta, setMeta] = useState<ResponseMetaData>({});
    const [page, setPage] = React.useState(0);
    const [totalPages, setTotalPage] = React.useState<number>(1);
    const [startDate, setStartDate] = useState<Date | string | null | undefined>();
    const [endDate, setEndDate] = useState<Date | string | null | undefined>();
    const { id } = useParams();
    const { state } = useAppSettings();
    const navigate = useNavigate();
    const [dialogId, setDialogId] = useState<number | null>(null);

    useEffect(() => {
        if (startDate === null && endDate === null) {
            fetchTableData({ page: 1, perPage: 10 })
        }
        else if (startDate !== null && endDate !== null) {
            fetchTableData({ page: 1, perPage: 10 })
        }
    }, [startDate, endDate])


    const fetchTableData = async ({ page = 1, perPage = 10 }) => {
        const response = await creditService.listCreditRequestsAdjustment({ page, perPage: perPage, dateRequestFrom: startDate ?? null, dateRequestTo: endDate ?? null });
        if (!!response) {
            const creditsListResponse = response?.data ?? [];
            const dataWithAdditionalColumns = await Promise.all(creditsListResponse.map(async (item: any) => {
                setTotalPage(response.meta?.totalPages ?? 1);
                setPage(response.meta?.page ?? 1);
                return {
                    ...item,
                    actions: item.status !== 'REQUEST' ? <Alignment position='center'>
                        <PromptTypo fontWeight='bold' color='primary' variant='subtitle1'>{statusTableDisplayMap[item.status]}</PromptTypo>
                    </Alignment> : _getRowActions(item),
                    requestDatetime: convertISOStringtoFormattedDateTime(item.requestDatetime),
                    transferDatetime: convertISOStringtoFormattedDateTime(item.transferDatetime),
                    processDatetime: convertISOStringtoFormattedDateTime(item.processDatetime),
                    amount: formatToCurrencyString(item.amount),
                    ownerName: `${item.ownerFirstname} ${item.ownerLastname}`,
                    adminName: `${item.requestUserFirstname} ${item.requestUserLastname}`,
                    status: statusTableDisplayMap[item.status]
                }
            }));

            const responseData = dataWithAdditionalColumns ?? [];
            setData(responseData);
            setMeta({
                totalCount: response.meta?.totalCount,
                totalPages: response.meta?.totalPages,
            });
        }
        else { return null }
    }


    const _getRowActions = (item: Record<string, any>) => {
        return <AppFloatingSpeedDeal actions={actions(item)} isOpen={true} onToggleOpen={() => { }} />
    }

    const handlePageChange = (newPage: number, perPage: number) => {
        setPage(newPage);
        fetchTableData({ page: newPage, perPage: perPage ?? 10 });
    };

    const formFields: AppFormData[] = [
        { name: 'dateFrom', value: '', required: false, placeholder: 'ช่วงเวลา', label: '', inputType: 'date-range' },
    ];

    const actions = (selectedIem: any) => {
        return [
            {
                icon: <Close />,
                bgColor: state.theme.palette.error.main,
                name: 'Reject',
                onClick: () => handleApprove(selectedIem, 'REJECTED')
            },
            {
                icon: <Check />,
                bgColor: state.theme.palette.secondary.main,
                name: 'Approve',
                onClick: () => handleApprove(selectedIem,
                    'APPROVED')
            },
        ]
    };

    const handleApprove = async (item: Record<string, any>, approvalStatus: 'APPROVED' | 'REJECTED') => {
        const statusDisplay = approvalStatus == 'APPROVED' ? `<span style="color:green">อนุมัติ</span>` : `<span style="color:red">ปฏิเสธ</span>`
        closeDialog(dialogId);

        const dialogContent = `
        <div style="display: flex; flex-direction: column; text-align: center;">
            <h2 style="font-family: 'Prompt', sans-serif; font-weight: bold;margin:0">${statusDisplay} การแก้ไขเครดิต</h6>
            <h4 style="font-family: 'Prompt', sans-serif;">คุณยืนยันที่จะ${statusDisplay}การแก้ไขเครดิต</h4>
            <h4 style="font-family: 'Prompt', sans-serif;margin:4">
                จำนวน <span style="color: ${state.theme.palette.secondary.main};">${formatToCurrencyString(item.amount)}</span> 
                ให้แก่บัญชี <span style="color: green;">${item.ownerFirstname} ${item.ownerLastname} </span> หรือไม่?
            </h4>
             <textarea id="remark-input-${dialogId}" 
                placeholder="กรุณาใส่เหตุผล (ถ้ามี)" 
                style="
                    margin-top: 10px; 
                    padding: 10px; 
                    font-family: 'Prompt', sans-serif; 
                    font-size: 14px; 
                    width: 100%; 
                    height: 80px; 
                    border: 1px solid #ccc; 
                    border-radius: 5px; 
                    resize: none;" 
            ></textarea>
        </div>
    `;
        DialogService.open(
            {
                html: dialogContent,
                confirmButtonText: 'ตกลง',
                cancelButtonText: 'ยกเลิก',
                confirmButtonColor: state.theme.palette.success.main,
                cancelButtonColor: state.theme.palette.error.main,
                showCancelButton: true,
            },
            async () => {
                const remarkInput = (document.getElementById(
                    `remark-input-${dialogId}`
                ) as HTMLTextAreaElement)?.value || '';

                await creditService.creditApproval(item.id, approvalStatus, remarkInput).then(async (res) => {
                    if (!!res) {
                        setStartDate(undefined);
                        setEndDate(undefined);
                        await fetchTableData({ page: 1, perPage: 10 });
                        SwalCompleted();
                    }
                });
            }
        );
    }

    const handleExportReport = async () => {
        const exportReportParams = {
            dateRequestFrom: startDate ?? null,
            dateRequestTo: endDate ?? null,
            perPage: meta.totalCount,
            page: 1,
        };
        const response = await creditService.listCreditRequestsAdjustment(exportReportParams);
        if (!!response) {
            const creditsListResponse = response?.data ?? [];
            const dataWithAdditionalColumns = await Promise.all(creditsListResponse.map(async (item: any) => {
                const status: { [key: string]: string } = {
                    'APPROVED': 'อนุมัติแล้ว',
                    'REJECTED': 'ปฏิเสธแล้ว',
                };
                const statusDisplay = status[item.status];
                return {
                    ...item,
                    requestDatetime: convertISOStringtoFormattedDateTime(item.requestDatetime),
                    transferDatetime: convertISOStringtoFormattedDateTime(item.transferDatetime),
                    processDatetime: convertISOStringtoFormattedDateTime(item.processDatetime),
                    amount: formatToCurrencyString(item.amount),
                    status: statusDisplay,
                }
            }));
            return dataWithAdditionalColumns ?? []
        }
        return [];
    }

    const clearFilter = () => {
        setStartDate(null);
        setEndDate(null);
        fetchTableData({ page: 1 });
    }


    return (
        <StyledContainer>
            <PromptTypo variant="h5">รายการขอจัดการเครดิต</PromptTypo>
            <Spacer size="md" />
            <CustomDateRangePicker onClearFilter={() => clearFilter()} onChange={(value) => {
                value.startDate && setStartDate(value.startDate);
                value.endDate && setEndDate(value.endDate);
            }} />
            <Spacer size="md" />
            {(startDate && endDate) &&
                <>
                    <ExcelGenerator
                        fileName={`[รายงานการจัดการเครดิต]${dayjs(startDate).format('DDMMM').toLowerCase()}_to_${dayjs(endDate).format('DDMMM').toLowerCase()}_${dayjs().format('HH:mm')}`}
                        columns={columns}
                        fetchReportData={handleExportReport}
                    />
                    <Spacer size="md" />
                </>
            }
            <Divider />
            <Divider />
            <Spacer size="md" />
            <PaginatedTable
                stickyActions={true}
                stickyHeader={false}
                showPagination={false}
                columns={columns}
                data={data as unknown as DataRow[] || []} />
            <AppPagination
                page={page ?? 1}
                count={+ (meta.totalPages ?? 0)}
                onPageChange={(page) => handlePageChange(page, 10)} />
        </StyledContainer >
    );
}

const columns: Column[] = [
    { field: 'id', label: 'เลขที่' },
    { field: 'adminName', label: 'Request By', ...tableContentConfig.common },
    { field: 'ownerName', label: 'Request To', ...tableContentConfig.common },
    { field: 'amount', label: 'Total Adjust', ...tableContentConfig.commonPrimary, align: 'right' },
    { field: 'status', label: 'Adjust Status', ...tableContentConfig.common },
    { field: 'requestRemark', label: 'Requester Remark', ...tableContentConfig.common },
    { field: 'adminRemark', label: 'Verifier Remark', ...tableContentConfig.common },
    { field: 'actions', label: '', ...tableContentConfig.action },
];

const reportColumns = columns.filter(f => f.field !== 'actions');
