import React, { useEffect, useState } from 'react'
import { StyledContainer } from '../components/styled/styled_flex_container';
import PromptTypo from '../components/typography/typography_prompt';
import { Box, Button, Grid, InputBase, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography, useTheme } from '@mui/material';
import Spacer from '../components/spacing/spacer';
import { cancelIncludedStatus, gameStateDisplay, gameStatusEnum, gameTransitionConfig, gameTransitionDisplay, gameTransitionType, SPACING_LG, SPACING_MD } from '../configs/constants';
import { Divider, Input } from 'rsuite';
import { StyledFillButton } from '../styled/filled_button';
import CustomPopOver from '../components/pop_over/custom_pop_over';
import CustomizedInputBase from '../styled/custom_input_based';
import { BorderAll } from '@mui/icons-material';
import gameService, { GameSession } from '../services/game_service';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import th from 'date-fns/esm/locale/th/index';
import { formatToCurrencyString } from '../helpers/number_formatter_helper';
import { SwalCompleted } from '../components/dialog/swal_alert';

function GameDetailPage() {
    const theme = useTheme();
    const { id } = useParams();
    const secondaryColor = theme.palette.secondary.main;
    const [gameDetail, setGameDetail] = useState<GameSession | undefined>(undefined);
    const [gameStatus, setGameStatus] = useState<gameStatusEnum | undefined>(gameStatusEnum.none);
    const [gameTransitionPosible, setGameTransitionPosible] = useState<gameTransitionType[]>([]);
    const [checkFirstNumberInput, setCheckFirstNumberInput] = useState<string>('');
    const [checkSecondNumberInput, setChecSecondkNumberInput] = useState<string>('');

    const currentTransitions = gameTransitionConfig.find(transition => transition.from.status === gameStatus);

    useEffect(() => {
        fetchGameDetail();
    }, [])

    useEffect(() => {
        setGameStatus(gameDetail?.state as gameStatusEnum)
    }, [gameDetail])

    const tableData = [
        { label: 'วันเปิดงวด', value: gameDetail?.startGameDate },
        { label: 'วันปิดงวด', value: dayjs(gameDetail?.endGameDatetime).locale("th").format('DD-MM-YYYY') },
        { label: 'เวลาปิดงวด', value: dayjs(gameDetail?.endGameDatetime).locale("th").format('HH:mm') },
        { label: 'ปิดงวดอัตโนมัติ', value: gameDetail?.isAutoEndEnable === true ? 'เปิดใช้งาน' : 'ปิดใช้งาน' },
        { label: 'ยอดรวม', value: formatToCurrencyString(gameDetail?.balance ?? 0) },
        { label: 'จำนวนโพยที่รับแล้ว', value: gameDetail?.ticketCount },
        { label: 'เปิดงวดโดย', value: gameDetail?.ownerId },
        { label: 'ปิดงวดโดย', value: 'ชื่อ Owner, Auto' },
    ];

    const fetchGameDetail = async () => {
        const res = await gameService.getGameSessionById(`${id}`);
        setGameDetail(res ?? undefined);
        setGameTransitionPosible(res?.nextPossibleTransitions ?? []);
    }

    const isCancelableIncluded = (inputStatus: gameTransitionType) => {
        return cancelIncludedStatus.includes(inputStatus);
    }

    const cancelable = () => {
        return !!gameTransitionPosible?.find(f => cancelIncludedStatus.includes(f))
    }

    const validateNumber = (value: string, digit: number) => {
        const firstPriceNumberPattern = /^[0-9]{6}$/;
        const lastTwoDigitNumberPattern = /^[0-9]{2}$/;
        return (digit === 6 ? firstPriceNumberPattern.test(value) : digit === 2 ? lastTwoDigitNumberPattern.test(value) : false);
    };

    const firstNumberValid = validateNumber(checkFirstNumberInput, 6);
    const secondNumberValid = validateNumber(checkSecondNumberInput, 2);


    const handleActionClick = async (targetStatus: gameTransitionType) => {
        const updateResponse = gameService.updateGame({ gameId: `${id}`, action: targetStatus }).then((res) => {
            if (!!res) {
                setGameStatus(res?.state);
                setCheckFirstNumberInput('');
                setChecSecondkNumberInput('');
                fetchGameDetail().then((_) => SwalCompleted())
            } else {
                console.error('Error updating game status');
            }
        })
    };

    const CheckNumberComponent = (): JSX.Element => <CustomPopOver
        triggerText={`ตรวจเลข`}
        confirmButton={<StyledFillButton disabled={checkFirstNumberInput.length !== 6 || checkSecondNumberInput.length !== 2}
        >ยืนยัน</StyledFillButton>}
        popOverContent={
            <>
                <PromptTypo>บน {`(รางวัลที่ 1 จำนวน 6 หลัก)`}</PromptTypo>
                <Spacer size='xs' />
                <CustomizedInputBase
                    value={checkFirstNumberInput}
                    onChange={(e: string) => {
                        setCheckFirstNumberInput(e)
                    }}
                    sx={{ border: `0.5px solid ${firstNumberValid || checkFirstNumberInput === '' ? themeColor.primary : themeColor.error}` }}
                    placeholder='ตรวจเลข'>
                </CustomizedInputBase>
                <Spacer size='xs' />
                <PromptTypo>ล่าง {`(เลขท้าย 2 ตัว)`}</PromptTypo>
                <Spacer size='xs' />
                <CustomizedInputBase
                    value={checkSecondNumberInput}
                    onChange={(e: string) => {
                        setChecSecondkNumberInput(e)
                    }}
                    sx={{ border: `0.5px solid ${secondNumberValid || checkSecondNumberInput === '' ? themeColor.primary : themeColor.error}` }}
                    placeholder='ตรวจเลข'>
                </CustomizedInputBase>
            </>
        }
        open={false}
    />

    const themeColor = {
        primary: theme.palette.primary.main,
        error: theme.palette.error.main
    }
    return (
        <StyledContainer>
            <PromptTypo variant="h5">รายละเอียดงวด</PromptTypo>
            <Spacer size='md' />
            <Paper sx={{ borderRadius: '16px', padding: SPACING_LG }}>
                <PromptTypo variant="h6">สถานะ : {!!gameStatus && gameStateDisplay[gameStatus]}</PromptTypo>
                <Spacer size='sm' />
                <Box sx={{ padding: 4, borderTop: `1px solid ${theme.palette.divider}` }}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={8}>
                            <Spacer size='md' />
                            <TableContainer component={Box} bgcolor={theme.palette.background.default}>
                                <Table>
                                    <TableBody>
                                        {tableData.map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell sx={{ fontWeight: 'bold' }}>{row.label}</TableCell>
                                                <TableCell>{row.value}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <StyledFillButton
                                onClick={() => setGameStatus(gameStatusEnum.cancelled)}
                                disabled={!cancelable()}
                                sx={{ marginTop: '16px', bgcolor: theme.palette.error.main }}>
                                ยกเลิกทั้งหมด
                            </StyledFillButton>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box display="flex" flexDirection="column" gap={2}>
                                {!!gameTransitionPosible.length && <Box sx={{ minHeight: '100px' }}>
                                    {gameTransitionPosible.map(tsn => {
                                        if (!isCancelableIncluded(tsn) && tsn !== gameTransitionType.checkNumber) return <StyledFillButton
                                            fullWidth
                                            disabled={false}
                                            variant="contained"
                                            onClick={() => handleActionClick(tsn)}
                                            sx={{
                                                maxWidth: '300px',
                                                marginBottom: SPACING_MD,
                                                bgcolor: theme.palette.secondary.main,
                                            }} >
                                            {gameTransitionDisplay[tsn]}
                                        </StyledFillButton>
                                        if (tsn === gameTransitionType.checkNumber)
                                            return <>
                                                <CheckNumberComponent />
                                                <Spacer size='md' />
                                            </>
                                    })}
                                </Box>}
                                <PromptTypo sx={{ marginTop: '16px' }} variant='h6'>
                                    รายงาน
                                </PromptTypo>
                                <StyledFillButton fullWidth sx={{ maxWidth: '300px' }}>
                                    ใบสรุปยอดเงิน
                                </StyledFillButton>
                                <StyledFillButton fullWidth sx={{ maxWidth: '300px' }} >
                                    ใบสรุปยอดเก็บ
                                </StyledFillButton>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
        </StyledContainer>
    )
}

export default GameDetailPage;
