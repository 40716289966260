import { de } from "react-day-picker/locale";
import baseAxios from "./axios_instance";
import { removeEmptyParam } from "./helper";
import { PaginationRequestParams, ResponseMetaData } from "./type";
import { useAuth } from "../stores/providers/auth_provider";
import { gameStatusEnum, gameTransitionType, STORAGE_KEY } from "../configs/constants";

type ResponseWithMeta<T> = {
    data?: GameSession[] | T[] | null;
    meta?: ResponseMetaData;
    message?: string;
}
export interface GameUpdateRequestBody {
    gameId: string | number;
    action: string;
}


export interface GameSession {
    id: number;
    ownerId: number;
    startGameDate: string;
    endGameDatetime: string;
    endGameActualDatetime: string;
    balance: number;
    ticketCount: number;
    isAutoEndEnable: boolean;
    startGameUserFirstname: string;
    startGameUserLastname: string;
    endGameUserFirstname: string;
    endGameUserLastname: string;
    isEndByAutoEnd: boolean;
    state: gameStatusEnum;
    nextPossibleTransitions: gameTransitionType[] | [];
}

export interface GameCreationRequestBody {
    endGameDatetime: string | Date;
    isAutoEndEnable: boolean;
}

export interface GameListRequestParams {
    startGameDate?: string;
}

class GameService {
    async getUserToken() {
        return localStorage.getItem(STORAGE_KEY.ACCESS_TOKEN);
    }
    async getHeaders() {
        return { Authorization: `Bearer ${await this.getUserToken()}` }
    }

    async listGameSession(params: PaginationRequestParams<GameListRequestParams>) {
        const paramsWithoutEmpty = removeEmptyParam<PaginationRequestParams<GameListRequestParams>>
            ({ ...params });
        try {
            const response = await baseAxios.get<ResponseWithMeta<GameSession>>
                (`/games`,
                    {
                        params: paramsWithoutEmpty,
                        headers: await this.getHeaders()
                    });

            return response.data;
        } catch (error) {
            console.log('Error fetching owners:', error);
            return { data: [] };
        }
    };

    async getGameSessionById(id: string | number): Promise<GameSession | null> {
        try {
            const response = await baseAxios.get<GameSession>
                (`/games/${id}`,
                    {
                        params: { gameId: id },
                        headers: await this.getHeaders()
                    });

            return response.data;
        } catch (error) {
            console.log('Error fetching owners:', error);
            return null
        }
    };

    async createGame(req: GameCreationRequestBody): Promise<GameSession | null> {
        try {
            const response = await baseAxios.post<GameSession>("/games", req, { headers: await this.getHeaders() });
            return response.data;
        } catch (error) {
            const errorData = (error as any)?.response?.data;
            return errorData;
        }
    }
    async updateGame(req: GameUpdateRequestBody): Promise<GameSession | null> {
        try {
            const response = await baseAxios.put<GameSession>(`/games/${req.gameId}/actions/${req.action}`, req, { headers: await this.getHeaders() });
            return response.data;
        } catch (error) {
            const errorData = (error as any)?.response?.data;
            return errorData;
        }
    }

}

const gameService = new GameService();
export default gameService;

