import ClearRounded from '@mui/icons-material/ClearRounded';
import { Box, IconButton, Paper, Stack, SxProps } from '@mui/material';
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import React, { CSSProperties, useEffect, useState } from 'react';
import DatePicker, { CalendarContainer } from 'react-datepicker';
import InputTime from 'react-datepicker/dist/input_time';
import 'react-datepicker/dist/react-datepicker.css';
import { th } from 'react-day-picker/locale';
import '../../App.css';
import { generalDateFormat, generalDateTimeFormat, SPACING_XXL } from '../../configs/constants';
import { StyledFillButton, StyledOutlineButton, StyledOutlineDatepickerButton, StyledTextButton } from '../../styled/filled_button';
import PromptTypo from '../typography/typography_prompt';
import Alignment from '../alignment';
import Spacer from '../spacing/spacer';
import { TimeRangePicker } from 'rsuite';
interface AppDatePickerProps {
    name: string;
    onChange?: (date: Date | null, event?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => void;
    value: string | null | undefined; // Allow null as a value
    placeholder: string;
    showTimeSelectOnly?: boolean;
    isDisabledTime?: boolean;
    style?: CSSProperties;
    error?: boolean;
    isClearable?: boolean;
    showTimeInput?: boolean;
    onClearData?: () => void;
    selectsRange?: boolean;
    customInputComponent?: React.ReactElement
    selectsStart?: boolean;
    selectsEnd?: boolean;
    startDate?: Date;
    endDate?: Date;
    maxDate?: Date;
    sx?: SxProps;
    withPortal?: boolean;
}

const AppDatePicker = (props: AppDatePickerProps) => {
    const {
        value,
        onChange,
        onClearData,
        isClearable,
        showTimeInput,
        selectsRange = false,
        customInputComponent,
        selectsStart,
        selectsEnd,
        startDate,
        endDate,
        maxDate,
        sx,
        withPortal = true
    } = props;
    const [date, setDate] = useState<Date | null>(value ? new Date(value) : null);
    const [open, setOpen] = useState<boolean>(false);
    useEffect(() => {
        const dateFormatted = dayjs(value).format(generalDateTimeFormat)
        setDate(dayjs(dateFormatted).isValid() ? new Date(dateFormatted) : null);
    }, [value]);

    const handleOnDateChanged = (date: Date | null) => {

        if (onChange) {
            onChange(dayjs(date).toDate());
        }
        setDate(dayjs(date).toDate());
    };

    useEffect(() => {
        if (onClearData) {
            onClearData();
            handleOnDateChanged(null);
        }
    }, [onClearData]);

    const renderValue = `${dayjs(date).isValid() ? dayjs(date).format(showTimeInput ? generalDateTimeFormat : generalDateFormat) : 'ยังไม่ได้เลือกวันที่'}`

    const defaultInputComponent = < Stack direction="row" >
        <StyledOutlineDatepickerButton sx={{
            padding: '4px 16px 4px 16px', height: SPACING_XXL, display: 'flex', alignItems: 'center', justifyContent: 'center'
        }}>
            <Stack direction="row" sx={{ width: '230px', justifyContent: 'space-between', alignItems: 'center' }}>
                <PromptTypo variant="body1">
                    {renderValue}
                </PromptTypo>
                {isClearable && <IconButton disabled={!!!date} sx={{ marginLeft: '16px' }} onClick={() => setDate(null)}>
                    <ClearRounded sx={{ ":hover": { color: 'red' } }} />
                </IconButton>}
            </Stack>
        </StyledOutlineDatepickerButton>
    </Stack >

    const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
        if (event.key === 'Enter') {
            setOpen(false);
        }
    };

    return (
        <DatePicker
            isClearable={false}
            selected={date}
            maxDate={maxDate}
            locale={th}
            withPortal={withPortal}
            dateFormat="yyyy-MM-dd HH:mm"
            timeFormat="HH:mm"
            timeInputLabel="ระบุเวลา"
            preventOpenOnFocus={true}
            shouldCloseOnSelect={!showTimeInput}
            showTimeInput={showTimeInput}
            placeholderText="เลือกวันที่ (และเวลา)"
            popperPlacement="right"
            popperClassName="form-builder--date-picker"
            popperContainer={({ children }) => <Box sx={{ marginRight: '0' }}>{children}</Box>}
            showPopperArrow={false}
            onChange={handleOnDateChanged}
            customInput={defaultInputComponent}
            onKeyDown={handleKeyDown}
        />

    );
};

export default AppDatePicker;
