import { AppBar, Box, Button, FormControlLabel, Switch, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { User } from '../../configs/constants';
import { translatePermissionToDisplayText } from '../../helpers/permission_helper';
import userService, { PermissionsResponse } from '../../services/user_service';
import { useLoading } from '../../stores/providers/linear_progress_provider';
import PromptTypo from '../typography/typography_prompt';

interface UserPermissionProps {
    selectedUser?: User;
    defaultPermission: string[];
    onChanged: (newValue: string[]) => void;
    onSubmit: (newValue: string[]) => void;
    onClose: () => void;
    dialogRef?: number

}

interface PermissionFormField {
    group: string;
    name: string;
    description: string;
    value: string;
    checked: boolean;
    disabled: boolean;
}

interface PermissionForms {
    id: number;
    name: string;
    position: number;
    permissions: {
        value: string;
        description: string;
        checked: boolean;
        disabled: boolean;
    }[]
}

function a11yProps(index: number) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}


const CustomTabPanel: React.FC<TabPanelProps> = (props) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
};

const PermissionAssignmentForm: React.FC<UserPermissionProps> = ({ selectedUser, defaultPermission, onChanged, onSubmit, onClose }) => {
    const [formState, setFormState] = useState<PermissionForms[]>([]);
    const [tabs, setTabs] = useState<PermissionsResponse[]>([]);
    const [currentPermissions, setCurrentPermissions] = useState<string[]>(selectedUser?.permissions ?? []);
    const [maxPermissionPosible, setMaxPermissionPosible] = useState<string[]>(defaultPermission ?? []);
    const { isLoading, startLoading, stopLoading } = useLoading();
    const [tabValue, setTabValue] = useState(0);

    useEffect(() => {
        fetchGroupsWithPermission().then((res) => {
            const permissionFilteredByMaxPermission = res.map(group => ({
                id: group.id,
                name: group.name,
                position: group.position,
                permissions: group.permissions.filter(permission => maxPermissionPosible.includes(permission)),
            }));
            setTabs(permissionFilteredByMaxPermission);
            initialForm(permissionFilteredByMaxPermission);

            console.log({ permissionFilteredByMaxPermission })
        });
    }, []);

    const initialForm = (permissionFilteredByMaxPermission: PermissionsResponse[]) => {
        const selectedUserPermission = selectedUser?.permissions ?? [];
        const updateFormState: PermissionForms[] = mapGroupsToState(permissionFilteredByMaxPermission, selectedUserPermission);
        setFormState(updateFormState);
    };

    const mapGroupsToState = (groups: PermissionsResponse[], selectedUserPermissions: string[]): PermissionForms[] => {
        const formattedPermissionForms: PermissionForms[] = groups.map(group => ({
            id: group.id,
            name: group.name,
            position: group.position,
            permissions: group.permissions.map(permission => ({
                value: permission,
                description: `${translatePermissionToDisplayText(permission)} ( ${permission.split('_').join(' ').toLowerCase()} )`,
                checked: selectedUserPermissions.includes(permission),
                disabled: false,
            }))
        }));

        console.log({ formattedPermissionForms });
        setFormState(formattedPermissionForms);
        return formattedPermissionForms;
    };

    const fetchGroupsWithPermission = async () => {
        startLoading();
        const permissionResponse = await userService.getPermissions();
        stopLoading();
        setTabs(permissionResponse ?? []);
        return permissionResponse;
    };

    const handleCheckboxChange = (permission: string, checked: boolean) => {
        const updatedPermissions = checked
            ? [...currentPermissions, permission]
            : currentPermissions.filter(p => p !== permission);

        setCurrentPermissions(updatedPermissions);
        const updatedForm = mapGroupsToState(tabs, updatedPermissions);
        setFormState(updatedForm);
        onChanged(updatedPermissions);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        onSubmit(currentPermissions);
    };

    const updateUser = async () => {
        if (!!selectedUser?.id && selectedUser.id !== undefined) {
            const userId = Number(selectedUser.id);

            if (!isNaN(userId)) { // Check if the conversion was successful
                await userService.updateUser(userId, { permissions: currentPermissions });
                return true;
            } else {
                console.error('User ID is not a valid number');
                return false;
            }
        } else {
            console.error('User ID is undefined');
            return false;
        }
    };

    const handleCancel = () => {
        console.log('Form cancelled');
    };


    const theme = useTheme();
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    return (
        <form onSubmit={handleSubmit} style={{ padding: '15px' }}>
            <PromptTypo variant="h5" align="center" gutterBottom>
                พนักงาน - สิทธิการใช้ : {selectedUser?.username}
            </PromptTypo>
            <AppBar position="static">
                <Tabs
                    value={tabValue}
                    onChange={handleChange}
                    indicatorColor="secondary"
                    textColor="inherit"
                    variant="scrollable"
                    aria-label="full width tabs example"
                >
                    {tabs.map((tab, index) => (
                        <Tab key={tab.name} label={tab.name} {...a11yProps(index)} />
                    ))}
                </Tabs>
            </AppBar>
            {!isLoading && formState.length > 0 && formState.map((group, index) => (
                <CustomTabPanel key={group.name} value={tabValue} index={index} {...theme}>
                    <div style={{ height: '480px', overflowY: 'auto' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <PromptTypo variant='h6'>สิทธิการใช้งาน</PromptTypo>
                                    </TableCell>
                                    <TableCell>
                                        <PromptTypo variant='h6'>อนุญาต</PromptTypo>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {group.permissions.map((field) => (
                                    <TableRow key={field.value}>
                                        <TableCell>
                                            <PromptTypo variant='subtitle1'>
                                                {field.description}
                                            </PromptTypo>
                                        </TableCell>
                                        <TableCell>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        onChange={(_, checked) => handleCheckboxChange(field.value, checked)}
                                                        checked={field.checked}
                                                    />
                                                }
                                                label=""
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </CustomTabPanel>
            ))}
            <Box textAlign="center" mt={2}>
                <Button type="submit" variant="contained" color="primary">
                    บันทึก
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Button variant="outlined" color="secondary" onClick={() => onClose()}>
                    ยกเลิก
                </Button>
            </Box>
        </form>
    );
};

export default PermissionAssignmentForm;
