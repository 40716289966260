import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, styled } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../configs/routes";
import PromptTypo from "../typography/typography_prompt";
import './styles.css';
import { useAppSettings } from "../../stores/context/app_setting_context";
import { hasAnyPermission, hasPermission } from '../../configs/permission';
import userService from "../../services/user_service";
import { useAuth } from "../../stores/providers/auth_provider";

const StyledListItemButtonSelected = styled(ListItemButton)(({ theme }) => ({
    padding: '10px',
    marginBottom: '5px',
    borderRadius: '4px',
    cursor: 'pointer',
    backgroundColor: theme.palette.secondary.dark,
}));

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
    padding: '10px',
    marginBottom: '5px',
    borderRadius: '4px',
    cursor: 'pointer',
}));

export default function MenuContent() {
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useAppSettings().state.theme;
    const handleNavigation = (route: string) => {
        navigate(route);
    };
    const { user } = useAuth();
    const filteredRoutes = routes.filter(route => route.position === 'side')
        .filter(route => {
            const allowedByRoleCodes = !!!route.fixedRoles ? true : route.fixedRoles?.includes(user?.roleCode);
            if (!!route.requiredPermission) {
                const isMatchedPermission = hasPermission(user?.permissions, route.requiredPermission ?? '-');
                const isShowPage = route.hidden === true ? false : true
                return route.implemented && isMatchedPermission && allowedByRoleCodes && isShowPage
            }
            else return (route.implemented && allowedByRoleCodes) && route.hidden !== true;
        });


    return (
        <Stack sx={{ flexGrow: 1, p: 1, justifyContent: 'space-between' }}>
            <List dense>
                {filteredRoutes.map((item, index) => (
                    <ListItem key={index} disablePadding sx={{ display: 'block' }}>
                        {location.pathname === item.path ?
                            <StyledListItemButtonSelected>
                                <ListItemIcon sx={{ color: theme.palette.text.primary }}>{item.icon}</ListItemIcon>
                                <ListItemText style={{ color: theme.palette.text.primary }}>
                                    <PromptTypo variant="subtitle1">
                                        {item.label}
                                    </PromptTypo>
                                </ListItemText>
                            </StyledListItemButtonSelected> :
                            <StyledListItemButton onClick={() => handleNavigation(item.path)}>
                                <ListItemIcon sx={{ color: theme.typography.body2.color }}>{item.icon}</ListItemIcon>
                                <ListItemText style={{ color: theme.palette.text.primary }}>
                                    <PromptTypo variant="subtitle1">
                                        {item.label}
                                    </PromptTypo>
                                </ListItemText>
                            </StyledListItemButton>
                        }
                    </ListItem>
                ))
                }
            </List>
        </Stack>
    );
}
