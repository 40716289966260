import { createTheme } from '@mui/material/styles';
import { brand, colorScheme, deepGoldPalette, goldenDeepBluePalette, gray, sampleColor2, sumColor } from './colors';

const dark = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#D9B779',
            light: '#F3B65D',
            dark: '#594A2E',
            contrastText: '#fefefe'
        },
        secondary: {
            main: '#0468BF',
            light: '#35D0BA',
            dark: '#0F2940',
            contrastText: '#fff',
        },
        background: {
            default: '#322f29',
            paper: '#212121'
        },
        text: {
            primary: '#D9B779',
            secondary: '#F2F2F2',
            disabled: '#cfced0'
        },
        action: {
            hover: '#202d40',
            selected: '#c18d21',
            focus: '#202d40',
            active: '#0B1926',
            disabled: '#677188',
            disabledBackground: '#0000040',

        },
        grey: gray,
        divider: '#444444'
    },
    typography: {
        fontFamily: 'prompt',
        body1: {
            color: '#F2F2F2'
        },
        body2: {
            color: '#F2F2F2'
        },
        subtitle1: {
            color: '#F2F2F2'
        },
        subtitle2: {
            color: '#F2F2F2'
        },
    },
    components: {
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    overflowX: "auto",
                    overflowY: "auto",
                },
            }
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    color: deepGoldPalette.frostWhite,
                    overflowX: "auto",
                    overflowY: "auto",
                },
            }
        },
        MuiIcon: {
            styleOverrides: {
                root: {
                    color: deepGoldPalette.frostWhite,
                },
            }
        }
    }
});

const light = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#006989',
            light: '#00698930',
            dark: '#10375C',
            contrastText: sampleColor2[0],
        },
        secondary: {
            main: '#0D4C92',
            light: '#0078FF',
            dark: brand[200],
            contrastText: sampleColor2[4],
        },
        background: {
            default: sampleColor2[4],
            paper: '#F2F2F0',
        },
        divider: deepGoldPalette.softSilver,
        text: {
            primary: '#11171D',
            secondary: '#003972',
            disabled: '#fefefe',
        },
        error: {
            main: '#ff4343',
        },
        action: {
            hover: '#0D4C9220',
            selected: '#59C1BD',
            focus: sampleColor2[2],
            active: colorScheme.serviceFee,
            disabled: '#9fb2ca',
            disabledBackground: '#ffffff1a',
        }

    },
    typography: {

        fontFamily: 'prompt',
        body1: {
            color: deepGoldPalette.abyssBlack,
        },
        body2: {
            color: deepGoldPalette.midnightOlive,
        },
        subtitle1: {
            color: deepGoldPalette.black,
        },
        subtitle2: {
            color: deepGoldPalette.black,
        },
    },
    components: {
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    overflowX: "auto",
                    overflowY: "auto",
                },
            }
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    color: deepGoldPalette.abyssBlack,
                    overflowX: "auto",
                    overflowY: "auto",
                },
            }
        },
        MuiIcon: {
            styleOverrides: {
                root: {
                    color: deepGoldPalette.abyssBlack,
                },
            }
        }
    }
});

export { light, dark };
