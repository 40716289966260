import React, { useEffect, useState } from 'react';
import { Stack, Box } from '@mui/material';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import 'react-datepicker/dist/react-datepicker.css';
import { Close, DateRange } from '@mui/icons-material';
import PromptTypo from '../typography/typography_prompt';
import Spacer from '../spacing/spacer';
import { generalDateFormat } from '../../configs/constants';
import { StyledOutlineDatepickerButton, StyledTextButton } from '../../styled/filled_button';
import { th } from 'react-day-picker/locale';
interface CustomDateRangePickerProps {
    onChange?: ({ startDate, endDate }: DateRangeValue) => void;
    onClearFilter?: () => void;
    maxRange?: number;
}

interface DateRangeValue {
    startDate: Date | string | undefined | null;
    endDate: Date | string | undefined | null;
}


export const CustomDateRangePicker: React.FC<CustomDateRangePickerProps> = ({ onChange, onClearFilter, maxRange }) => {
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    useEffect(() => {
        if (!!startDate && !!endDate) {
            onChange?.({ startDate, endDate });
        }
    }, [startDate, endDate]);

    const DatePikerCustomInput = <StyledOutlineDatepickerButton>
        <Spacer size="xs" />
        <Stack direction={'row'} alignItems={'center'}>
            <DateRange />
            <Spacer size="md" />
            <PromptTypo>
                {startDate
                    ? dayjs(startDate).format('DD/MM/YYYY')
                    : 'เริ่มต้น - '}
                {startDate && ' ถึง '}
                {endDate
                    ? dayjs(endDate).format('DD/MM/YYYY')
                    : 'สิ้นสุด'}
            </PromptTypo>
            <Spacer size="xs" />
        </Stack>
    </StyledOutlineDatepickerButton>


    const handleClear = () => {
        setDateRange([null, null]);
        onChange?.({ startDate: null, endDate: null });
        onClearFilter?.();
    };

    return (
        <Stack direction={'row'}>
            <Box>
                <PromptTypo sx={{ fontSize: '10px' }} textAlign={'left'}>
                    เลือกช่วงวันที่
                </PromptTypo>
                <Spacer size="xs" />
                <Stack direction={'row'}>
                    <DatePicker
                        shouldCloseOnSelect
                        minDate={undefined}
                        maxDate={startDate
                            ? dayjs(startDate).add(3, 'month').isBefore(dayjs())
                                ? dayjs(startDate).add(3, 'month').toDate() // 3 months from startDate
                                : dayjs().toDate()
                            : dayjs().toDate()}
                        locale={th}
                        dateFormat={generalDateFormat}
                        selectsRange={true}
                        startDate={startDate ?? undefined}
                        endDate={endDate ?? undefined}
                        onChange={(update: any) => {
                            setDateRange(update);
                        }}
                        customInput={DatePikerCustomInput}
                        withPortal
                    />
                    <Spacer size="md" />
                    <StyledTextButton
                        sx={{ visibility: (!!startDate || endDate) ? 'visible' : 'hidden' }}
                        onClick={handleClear}
                        color='error'>
                        <Close color='error' />
                        <Spacer size="xs" />
                        <PromptTypo variant='body2'>
                            ล้างค่า
                        </PromptTypo>

                    </StyledTextButton>
                </Stack>

            </Box>
        </Stack>
    );
};

export default CustomDateRangePicker;
