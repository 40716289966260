import React, { useEffect, useState } from 'react';
import { Box, Collapse } from '@mui/material';
import { animated } from '@react-spring/web';
import dayjs from 'dayjs';
import { StyledOutlineButton } from '../../styled/filled_button';
import { SwalCompleted } from '../dialog/swal_alert';
import SpringHero from '../spring/spring_hero';
import PromptTypo from '../typography/typography_prompt';
import FormBuilder, { AppFormData, Choice } from './form_builder';
import creditService, { BankAccountResponse, TopUpRequestBody } from '../../services/credit_service';
import { formatNumberString } from '../spring/string_helper';

const MIN_TOPUP_AMOUNT = 5000;
const MAX_TOPUP_DATE = new Date();

interface TopUpRequestFormProps {
    creditBalance: string | null;
    refreshTopUps: () => Promise<void>;
    currentUser: any; // Replace with your `currentUser` type
}

const TopUpRequestFormComponent: React.FC<TopUpRequestFormProps> = ({ creditBalance, refreshTopUps, currentUser }) => {
    const [open, setOpen] = useState(false);
    const [fields, setFields] = useState<AppFormData[]>([]);
    const [minTopUp, setMinTopUp] = useState<number>(1)
    const [bankResponse, setBankResponse] = useState<BankAccountResponse[]>([]);

    const formFields: AppFormData[] = [
        {
            name: 'bankAccount',
            inputType: 'select',
            value: `${bankResponse[0] ?? ''}`,
            required: true,
            placeholder: 'บัญชีธนาคาร',
            label: 'ฝากเข้าบัญชี',
            choices: [],
            textFieldProps: {
                onChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                    console.log(event.target.value)
                }
            }
        },
        {
            name: 'amount',
            value: '',
            inputType: 'text',
            required: true,
            placeholder: `จำนวนเงินขั้นต่ำ ${minTopUp} บาท`,
            label: 'จำนวนเงิน',
            minValue: '1',
        },
        {
            name: 'transferedDate',
            inputType: 'dateTime',
            value: '',
            maxValue: MAX_TOPUP_DATE.toString(),
            required: true,
            placeholder: `กรุณาเลือกวันและเวลาที่ฝาก`,
            label: 'วันและเวลาที่ฝาก',
            isClearable: true,
        },
        {
            name: 'remark',
            value: '',
            inputType: 'text',
            required: false,
            placeholder: `หมายเหตุ`,
            label: 'หมายเหตุ',
        },
    ];

    useEffect(() => {
        setMinTopUp(bankResponse[0]?.minimumAmount ?? 1)
    }, [bankResponse]);

    useEffect(() => {
        setFields([...formFields]);
        fetchBankAccounts();
    }, []);

    const fetchBankAccounts = async () => {
        const res: BankAccountResponse[] | [] = await creditService.listBankAccount();
        setBankResponse(res);

        if (res.length > 0) { // Check if the response is not empty
            const updatedChoices = res.map((bank) => ({
                label: `${bank.bankName} - ${bank.bankAccountNumber} - ${bank.bankAccountName}`,
                value: bank.id,
                minTransaction: bank.minimumAmount,
            })) as unknown as Choice[];

            // Update formFields with choices and default value
            const updatedFields = [...formFields];
            updatedFields[0].choices = updatedChoices;

            // Set the default value of 'bankAccount' to the first item in the response
            updatedFields[0].value = `${res[0].id}`;

            setFields(updatedFields);
        }
    };

    const createTopUpRequest = async (formData: Record<string, string | number>) => {
        const requestBody: TopUpRequestBody = {
            ownerId: currentUser.ownerId ?? 0,
            bankAccountId: formData.bankAccount as string,
            amount: formData.amount as number,
            requestDatetime: dayjs().toISOString(),
            transferDatetime: dayjs(formData.transferedDate).toISOString(),
            requestRemark: `${formData.remark}`,
            type: 'TOPUP',
        };

        const response = await creditService.creditTopUpRequest(requestBody);
        console.log("response", response);
        if (response) {
            setOpen(false); // Close the form
            refreshTopUps() // Wait for the top-ups to refresh
                .then(() => {
                    SwalCompleted(); // Show completion alert after refresh is done
                })
                .catch((error) => {
                    console.error("Error refreshing top-ups:", error);
                });
        }
    };

    const handleFormSubmit = (data: Record<string, string | number>) => {
        createTopUpRequest(data);
    };

    const handleClearForm = () => {
        setFields(formFields.map((field) => ({ ...field })));
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    return (
        <Box sx={{ minHeight: 50, padding: 0, width: '100%' }}>
            <Collapse in={!open}>
                <StyledOutlineButton
                    color="primary"
                    onClick={handleToggle}
                    style={{ marginBottom: '1rem' }}
                >
                    แจ้งเติมเครดิต
                </StyledOutlineButton>
                <PromptTypo variant="h6" fontWeight='bold' sx={{ width: '100%', textAlign: 'right' }}>
                    เครดิตคงเหลือ : {formatNumberString(`${creditBalance ?? ''}`)}
                </PromptTypo>
            </Collapse>
            <Collapse in={open} timeout={500}>
                <animated.div>
                    <SpringHero open={open} setOpen={setOpen}>
                        <form>
                            <FormBuilder
                                formTitle="แจ้งเติมเครดิต"
                                fields={fields}
                                onSubmit={handleFormSubmit}
                                onCancel={handleToggle}
                            />
                        </form>
                    </SpringHero>
                </animated.div>
            </Collapse>
        </Box>
    );
};

export default TopUpRequestFormComponent;
