// Layout.tsx
import styled from 'styled-components';

interface ColProps {
  size?: number;
  centerX?: boolean;
  centerY?: boolean;
}

const StyledContainer = styled.div`
  max-width: 100vw; // Adjust max width as needed
  margin: 0 auto; // Center the container
  padding: 0 16px; // Adjust side padding as needed
`;

const Row = styled.div<{ centerX?: boolean; centerY?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  margin: -8px; // Adjust as needed for spacing
  flex-direction:row;
  justify-content: ${({ centerX }) => (centerX ? 'center' : 'flex-start')};
  align-items: ${({ centerY }) => (centerY ? 'center' : 'flex-start')};
  height: 100%; // Ensure full height for vertical centering
  width:'100vw;
`;

const Col = styled.div<ColProps>`
  flex: 1;
  padding: 8px; // Adjust as needed for spacing
  max-width: ${({ size }) => (size ? `${(size / 12) * 100}%` : '100%')};

  @media (max-width: 768px) {
    max-width: ${({ size }) => (size ? '100%' : '100%')}; // Full width on smaller screens
  }
`;

export { StyledContainer, Row, Col };


export const sxCenter = { display: 'flex', alignItems: 'center', justifyContent: 'center' };