import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Container,
    MenuItem,
    Select,
    Stack,
    StyledComponentProps,
    SxProps,
} from '@mui/material';
import FormBuilder, { AppFormData } from '../form/form_builder';
import { CreditStatusFilter, OwnerStatusTypeFilter } from '../../services/credit_service';

const MAX_TOPUP_DATE = new Date();


export const CREDIT_STATUS = [
    { value: CreditStatusFilter.ALL, label: 'ทั้งหมด' },
    { value: CreditStatusFilter.REQUESTED, label: 'รอดำเนินการ' },
    { value: CreditStatusFilter.APPROVED, label: 'อนุมัติแล้ว' },
    { value: CreditStatusFilter.REJECTED, label: 'ปฏิเสธแล้ว' },
];
export const OWNER_STATUS = [
    { value: OwnerStatusTypeFilter.ALL, label: 'ทั้งหมด' },
    { value: OwnerStatusTypeFilter.ACTIVE, label: 'ใข้งาน' },
    { value: OwnerStatusTypeFilter.DISABLED, label: 'ปิดการใช้งาน' },
];

const formFields: AppFormData[] = [
    { name: 'status', inputType: 'select', value: 'all', required: false, placeholder: 'สถานะ', label: 'สถานะ', choices: CREDIT_STATUS },
    { name: '', value: '', inputType: 'spacer' },
    { name: 'dateRequest', inputType: 'date', value: '', maxValue: MAX_TOPUP_DATE.toString(), placeholder: `วันที่แจ้ง`, label: 'วันที่แจ้ง', isClearable: true },
    { name: 'dateTransfer', inputType: 'date', value: '', maxValue: MAX_TOPUP_DATE.toString(), required: false, placeholder: `วันที่โอน`, label: 'วันที่โอน', isClearable: true },
];


export type CreditRequestFilterOptions = {
    status?: string | undefined | null;
    dateRequest?: string | undefined | null;
    dateTransfer?: string | undefined | null;
};

type CreditTableFilterProps = {
    style?: StyledComponentProps;
    sx?: SxProps;
    onChange?: (newValue: CreditRequestFilterOptions) => void;
    onSubmit?: (newValue: CreditRequestFilterOptions) => void;
};

export default function CreditTableFilter({
    style,
    sx,
    onChange,
    onSubmit,
}: CreditTableFilterProps) {
    const [fields, setFields] = useState<AppFormData[]>([...formFields]);

    return (
        <form>
            <FormBuilder
                containerSize={window.innerWidth - 256}
                sx={{ margin: 0 }}
                itemsPerRow={2}
                formTitle=""
                fields={fields}
                onSubmit={(data: any) => {
                    console.log({ data })
                    onSubmit?.({
                        status: data.status,
                        dateRequest: `${data.dateRequest ?? ''}`,
                        dateTransfer: `${data.dateTransfer ?? ''}`,
                    })
                }}
            />
        </form>
    );
}
