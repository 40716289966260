import dayjs from "dayjs";
import { generalDateTimeFormat } from "../configs/constants";
import "dayjs/locale/th"; // Import Thai locale
import relativeTime from "dayjs/plugin/relativeTime";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';

// Extend dayjs with the relativeTime plugin
dayjs.extend(relativeTime);

// Activate Thai locale
dayjs.locale("th");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

// Function to convert a date to a relative time string
export const convertToRelative = (date: string): string => {
    return dayjs(date).fromNow(); // Calculate relative time from now
};

export const formatDateTime = (date: string | Date | null | undefined) => {
    const formattedDate = dayjs(date).format(generalDateTimeFormat);
    return formattedDate === 'Invalid Date' ? generalDateTimeFormat : '-';

};

export const convertISOStringtoFormattedDateTime = (dateString: string): string => {
  if (!dateString) {
    return '-';
  }
  const parsedDate = dayjs(dateString);
  if (!parsedDate.isValid()) {
    return "-";
  }

  const day = parsedDate.format('DD');
  const month = parsedDate.format('MM');
  const year = parsedDate.format('YYYY');
  const hours = parsedDate.format('HH');
  const minutes = parsedDate.format('mm');

  return `${day}/${month}/${year} - ${hours}:${minutes}`;
};

export function formatDateToUTCOrLocal(date: string, toUTC = true) {
    // Parse the input date
    let formattedDate = dayjs(date).startOf('day'); // Set the time to 00:00

    // Convert to UTC or local time based on `toUTC` flag
    if (toUTC) {
        formattedDate = formattedDate.utc(); // Convert to UTC
    } else {
        formattedDate = formattedDate.local(); // Convert to local time zone
    }

    return formattedDate.toDate(); // Return as a native JavaScript Date object
}

export function utcDateFotmatted(date: string | Date) {
    const inputDate = dayjs(date).format()
    const utcDate = formatDateToUTCOrLocal(inputDate);
    return utcDate;
}

export const dateFlatStringFormatted = (date: Date | string | null | undefined): string => {
    if (!date) return '';

    const d = new Date(date);
    const day = d.getDate().toString().padStart(2, '0'); // Adds leading zero if day < 10
    const month = (d.getMonth() + 1).toString().padStart(2, '0'); // Adds leading zero if month < 10
    const year = d.getFullYear();

    return `${day}${month}`;
};

// export const dateInTimeZone = ()