import { Autocomplete, AutocompleteRenderInputParams, Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import React, { useState } from 'react';
import { Close } from '@mui/icons-material';
import '../../App.css'
import AppReactSelector from '../form/react_selector';
import { ActionMeta, InputActionMeta } from 'react-select';
import { MOCK_AGENT_NAME_LIST } from '../../configs/mock';

export interface OdmntOption {
    label: string;
    value?: string | number | null | undefined;

}

interface SelectSearchProps {
    options: OdmntOption[];
    title: string;
}

const OdmntSelectSearch: React.FC<SelectSearchProps> = ({ options, title }) => {
    const [selectedValue, setSelectedValue] = useState<OdmntOption | null>(null);
    const [searchQuery, setSearchQuery] = useState<string>('');

    const filteredOptions = options.filter((option) =>
        option.label.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleOnChange = (val: any) => { }

    return (
        <FormControl fullWidth>
            <Autocomplete
                options={filteredOptions}
                value={selectedValue}
                onChange={(_, newValue) => setSelectedValue(newValue)}
                getOptionLabel={(option) => option.label}
                clearIcon={<Close color='error'></Close>}
                popupIcon
                renderInput={(params) =>
                    <TextField
                        {...params}
                        sx={{ padding: '12px 12px' }}
                        variant="outlined"
                        size="small"
                        placeholder={title}
                    />
                }
            />
        </FormControl>
    );
};


export default OdmntSelectSearch;
