import { Box, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { EmptyComponent } from '../components/blank/blank_table';
import TopUpRequestFormComponent from '../components/form/top_up_request_form_component';
import Spacer from '../components/spacing/spacer';
import { formatNumberString } from '../components/spring/string_helper';
import { Column } from '../components/table/hoverable_table_row';
import PaginatedTable from '../components/template/pagination_table_page';
import PromptTypo from '../components/typography/typography_prompt';
import { renderValueIfNotEmpty } from '../configs/functions';
import { translationMap } from '../configs/translation';
import { convertISOStringtoFormattedDateTime } from '../helpers/date_time_helper';
import creditService, { CreditRequestData, CreditRequestParams, TopUpData } from '../services/credit_service';
import { defaultResponseMetaData, ResponseMetaData } from '../services/type';
import { useCreditsStore } from '../stores/hooks/credits_hook';
import { useAuth } from '../stores/providers/auth_provider';

const TopUpPage: React.FC = () => {
    const [topUps, setTopUps] = useState<TopUpData[]>([]);
    const [creditBalance, setCreditBalance] = useState<string | null>(null);
    const [pagination, setPagination] = useState<any>({ page: 1, perPage: 30, total: 0 }); const { user: currentUser } = useAuth();
    const [meta, setMeta] = useState<ResponseMetaData>(defaultResponseMetaData);
    const { getCreditRequestList } = useCreditsStore();

    useEffect(() => {
        refreshTopUps();
        getOwnerBalance();
    }, []);

    const refreshTopUps = async () => {
        const requestOptions: CreditRequestParams = {
            type: 'TOPUP',
            page: pagination.page,
            perPage: pagination.perPage,
        }
        await getCreditRequestList(requestOptions, () => { }).then(res => {
            if (res && Array.isArray(res.data) && res.data.length !== 0) {
                const data = res.data.map((item: CreditRequestData) => ({
                    id: item.id,
                    targetBankAccount: item.bankAccountId,
                    amount: item.amount,
                    status: item.status,
                    requestedAt: item.requestDatetime,
                    createdAt: item.transferDatetime,
                    pDate: item.processDatetime,
                    remarkAdmin: item.adminRemark?.trim() ?? '-',
                    remark: item.requestRemark?.trim() ?? '-',
                    bankAccount: `${item.bankAccountNumber} (${item.bankAccountBankName})`
                } as unknown as TopUpData));
                setTopUps(data);
            }
            else {
                setTopUps([]);
            }
        })
    };

    const getOwnerBalance = async () => {
        const ownerBalanceResponse = await creditService.listCreditTxn({ page: 1, perPage: 1, owner: currentUser.ownerId });
        const balanceResponse = ownerBalanceResponse?.data?.[0]?.balance;
        setCreditBalance(balanceResponse ? balanceResponse.toString() : '0');
    };

    return (
        <Box>
            <TopUpRequestFormComponent
                creditBalance={creditBalance}
                refreshTopUps={refreshTopUps}
                currentUser={currentUser}
            />
            {topUps.length > 0 ? (
                <>
                    <Spacer size="lg" />
                    <PaginatedTable columns={columns} data={topUps} showPagination={false} />
                </>
            ) : (
                <EmptyComponent displayedText="ไม่พบข้อมูลประวัติการแจ้งเติมเครดิต" />
            )}
        </Box>
    );
};

export default TopUpPage;

export const columns: Column[] = [
    { label: 'เลขที่', field: 'id' },
    { label: 'วันที่แจ้ง', field: 'requestedAt', render: (value: string) => `${value ? convertISOStringtoFormattedDateTime(value) : '-'}` },
    { label: 'วันที่ฝาก', field: 'createdAt', render: (value: string) => `${value ? convertISOStringtoFormattedDateTime(value) : '-'}` },
    { label: 'บัญชี', field: 'bankAccount', render: (value: string) => toColumnEllipsisStyle(value) },
    { label: 'จำนวนเงิน', field: 'amount', align: 'right', render: (value: any) => formatNumberString(value) },
    {
        label: 'หมายเหตุ', field: 'remark', render: (value: any) => renderValueIfNotEmpty(value)
    },
    {
        label: 'สถานะ', field: 'status', highlighten: true, render: (value: string) => {
            const creditStatusMap = translationMap.creditStatus as Record<string, string>;
            const statusDisplayMap = creditStatusMap[`${value}`];
            return statusDisplayMap ?? value;
        },
    },
    { label: 'ปิดเมื่อ', field: 'pDate', render: (value: string) => `${value ? convertISOStringtoFormattedDateTime(value) : '-'}` },
    {
        label: 'Admin note', field: 'remarkAdmin', render: (value: any) => {
            return `${value ?? '-'}`
        }
    },
];

const toColumnEllipsisStyle = (value: string) => <div title={`${value || '-'}`}>
    <Tooltip title={`${value || '-'}`}>
        <PromptTypo sx={{
            maxWidth: '300px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }}>
            {value || '-'}
        </PromptTypo>
    </Tooltip>
</div>