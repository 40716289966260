import { useEffect } from 'react';
import { STORAGE_KEY } from '../../configs/constants';
import { authService } from '../../services/auth_service';
import { useLoading } from '../providers/linear_progress_provider';
import baseAxios from '../../services/axios_instance';
import Swal from 'sweetalert2';
import dialogService from '../../components/dialog/confirmation_by_sweet_alert2';
import { useNavigate } from 'react-router-dom';
import useAuth from './auth_hook';

const shouldLogoutStatuses = [401, 403];

const refreshAccessToken = async (): Promise<string | null> => {
    try {
        const newAccessToken = await authService.renewAccessToken();
        return newAccessToken;
    } catch (error) {
        console.error('Failed to refresh access token:', error);
        return null;
    }
};

const useAxiosInterceptors = () => {
    const { startLoading, stopLoading } = useLoading();
    const { userToken, logout } = useAuth();
    const navigate = useNavigate();

    // Request registry to track ongoing requests
    const activeRequests = new Set<string>();


    useEffect(() => {
        const handleLoading = (isLoading: boolean) => {
            isLoading ? startLoading() : stopLoading();
            isLoading && dialogService.loading();
            !isLoading && Swal.close();
        };

        const requestInterceptor = baseAxios.interceptors.request.use(
            async (config) => {
                handleLoading(true);

                // Prevent duplicate API requests
                const requestKey = config.url || '';
                if (activeRequests.has(requestKey)) {
                    return Promise.reject({ message: 'Duplicate request blocked', config });
                }
                activeRequests.add(requestKey);

                // Wait until the token is fully available
                let token = userToken?.accessToken;
                if (!token) {
                    token = `${await localStorage.getItem(STORAGE_KEY.ACCESS_TOKEN)}`;
                }

                if (token) {
                    config.headers["Authorization"] = `Bearer ${token}`;
                } else {
                    console.warn("No token available for the request!");
                }

                return config;
            },
            (error) => {
                handleLoading(false);
                return Promise.reject(error);
            }
        );

        const responseInterceptor = baseAxios.interceptors.response.use(
            (response) => {
                handleLoading(false);

                // Remove the request from the registry
                const requestKey = response.config.url || '';
                activeRequests.delete(requestKey);

                return response;
            },
            async (error) => {
                const errorStatusCode = error.response.status;
                handleLoading(false);

                const originalRequest = error.config;

                // Remove the request from the registry on error
                const requestKey = originalRequest.url || '';
                activeRequests.delete(requestKey);
                const errorResponse = error.response;
                const errorHttpCode = error.response.status;
                const errorCodeApiResponse = error.response.data.code;
                // Handle 401 errors (unauthorized)

                if (
                    errorHttpCode === 401 &&
                    !originalRequest._retry
                ) {
                    originalRequest._retry = true; // Mark request as retried
                    const newAccessToken = await refreshAccessToken();

                    if (newAccessToken) {
                        // Update token in localStorage and axios headers
                        localStorage.setItem(STORAGE_KEY.ACCESS_TOKEN, newAccessToken);
                        baseAxios.defaults.headers.common["Authorization"] = `Bearer ${newAccessToken}`;
                        originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
                        return baseAxios(originalRequest); // Retry the original request
                    }

                    // If token refresh fails, logout
                    logout();
                    navigate('/login', { replace: true });
                    return Promise.reject(error);
                }

                // Handle logout on 401/403 if token exists
                if (
                    shouldLogoutStatuses.includes(errorResponse?.status)
                ) {
                    logout();
                    navigate('/login', { replace: true });
                    return;
                }


                if (errorHttpCode === 400) {
                    let title = 'ทำรายการไม่สำเร็จ';
                    let message = '';
                    if (error.response.data.code == "CR_002") message = 'ไม่สามารถอนุมัติคำขอจัดการเครดิตของตัวเองได้';
                    else if (error.code === 'ECONNABORTED' || error.code === 'ERR_NETWORK') {
                        title = 'ข้อผิดพลาดทางเครือข่าย';
                        message = 'มีปัญหาในการเชื่อมต่อเครือข่ายของคุณ กรุณาตรวจสอบอินเทอร์เน็ตและลองใหม่อีกครั้ง';
                    }
                    else {
                        message = 'กรุณาทำรายการใหม่ หรือลองใหม่อีกครั้งในภายหลัง'
                    }

                    Swal.fire({
                        icon: 'error',
                        title: title,
                        text: message,
                    });

                } else if (errorStatusCode >= 500) {
                    if (error.response.data.message) {
                        Swal.fire({
                            icon: 'error',
                            title: 'ทำรายการไม่สำเร็จ',
                            text: `${error.response.data.message}`,
                        });
                    }
                    else
                        Swal.fire({
                            icon: 'error',
                            title: 'ทำรายการไม่สำเร็จ',
                            text: `กรุณาทำรายการใหม่อีกครั้งในภายหลัง`, // Translated as "Error: {error.message}"
                        });
                }
                if (errorStatusCode === 401 || errorStatusCode === 403) {
                    Swal.fire({
                        icon: 'error',
                        title: 'หมดเวลาเชื่อมต่อ',
                        text: `กรุณาเข้าสู่ระบบอีกครั้ง`,
                    });
                    navigate('/login', { replace: true });
                }
                return Promise.reject(error);
            }
        );

        return () => {
            baseAxios.interceptors.request.eject(requestInterceptor);
            baseAxios.interceptors.response.eject(responseInterceptor);
        };
    }, [startLoading, stopLoading, userToken, logout, navigate]);
};

export default useAxiosInterceptors;
