import { Box, Input, Paper, Stack, TextField, useTheme, Link } from '@mui/material'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import Spacer from '../components/spacing/spacer'
import { StyledContainer } from '../components/styled/styled_flex_container'
import PromptTypo from '../components/typography/typography_prompt'
import { defaultPaginationParams, defaultResponseMetaData, PaginationRequestParams, ResponseMetaData } from '../services/type'
import { useAppSettings } from '../stores/context/app_setting_context'
import { StyledFillButton, StyledOutlineButton, StyledTextButton } from '../styled/filled_button'
import PaginatedTable from '../components/template/pagination_table_page'
import { Column, DataRow } from '../components/table/hoverable_table_row'
import { gameStateDisplay, tableContentConfig } from '../configs/constants'
import GameCreateForm from '../components/dialog/game_create_form'
import { useDialog } from '../stores/providers/dialog_provider'
import StyledTextFieldWithErrorText from '../components/styled/styled_textfield'
import gameService, { GameSession } from '../services/game_service'
import { SwalAlert, SwalCompleted, SwalLeaveConfirm } from '../components/dialog/swal_alert'
import { convertISOStringtoFormattedDateTime, formatDateToUTCOrLocal } from '../helpers/date_time_helper'
import { useNavigate } from 'react-router-dom'
import { formatToCurrencyString } from '../helpers/number_formatter_helper'
import AgentOrderTableComponent from '../components/order/order_table_component'
import DialogService from '../components/dialog/confirmation_by_sweet_alert2'
import OdmntSelectSearch from '../components/odmnt_selector/odmnt_selector'
import { MOCK_AGENT_NAME_LIST } from '../configs/mock'

const DATE_FORMAT = 'DD-MM-YYYY';

const EXAMPLE_DATA = [
    { orderNo: 'PO123456', amount: 1500, orderDate: '2025-01-06', id: 1 },
    { orderNo: 'PO987654', amount: 2000, orderDate: '2025-01-05', id: 2 },
    { orderNo: 'PO567890', amount: 500, orderDate: '2025-01-04', id: 3 },
];

const AgentOrderingPage: React.FC = () => {
    const { state } = useAppSettings();
    const navigate = useNavigate();
    const { openDialog, closeDialog } = useDialog();
    const [dialogId, setDialogId] = useState<any>(null);
    const [tableData, setTableData] = useState<typeof EXAMPLE_DATA>(EXAMPLE_DATA);
    const [meta, setMeta] = useState<ResponseMetaData>(defaultResponseMetaData);
    const [gameDate, setGameDate] = useState<string | undefined>('');
    const [filter, setFilter] = useState<PaginationRequestParams<{}>>(defaultPaginationParams);
    const [showCloseConfirm, setShowCloseConfirm] = useState(false);
    useEffect(() => {
        fetchData();
    }, [])


    const formatDate = (value: string): string => {
        let cleanedValue = value.replace(/\D/g, '');
        if (cleanedValue.length > 2) {
            cleanedValue = cleanedValue.slice(0, 2) + '-' + cleanedValue.slice(2);
        }
        if (cleanedValue.length > 5) {
            cleanedValue = cleanedValue.slice(0, 5) + '-' + cleanedValue.slice(5);
        }

        return cleanedValue.slice(0, 10);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setGameDate(formatDate(value));
    };
    const handleBlur = () => {
        const isValidDate = dayjs(gameDate, DATE_FORMAT).isValid();
        if (gameDate) {
            const newDateValue = isValidDate ? gameDate : ''
            setGameDate(newDateValue);
        }
    };

    const handleSearch = () => {
        if (filter.page !== 1) {
            setFilter((prevFilter) => ({ ...prevFilter, page: 1 }));
        } else {
            fetchData();
        }
    };

    const fetchData = async ({ page = 1, withSwalComp = false } = filter) => {
        try {
            // const response = await gameService.listGameSession({
            //     ...filter,
            //     startGameDate: dayjs(gameDate).isValid() ? dayjs(gameDate, 'DD-MM-YYYY').utc(true) : null,
            //     page,
            // });

            // const data = response?.data?.map((e) => (
            //     { ...e, actions: { id: e.id } }
            // )) ?? [];

            // setTableData(data);
            // setMeta(response?.meta ?? {})
            // if (withSwalComp) {
            //     SwalCompleted();
            // }
        } catch (error) {
            // console.error("Error fetching rates:", error);
        }
    }

    const theme = useTheme();
    const { paper: bgPaper, default: bgDefault } = theme.palette.background;
    const textColor = theme.palette.text.secondary;
    const secondaryColor = theme.palette.secondary.main;

    const handleOpenDialog = ({ type = '' }) => {
        if (type === 'create-order')
            openDialog(
                <AgentOrderTableComponent />,
                "lg", true
            );
    };

    const handleCloseDialog = () => {
        SwalLeaveConfirm(
            () => closeDialog(),
            () => { }
        )
    }

    // setShowCloseConfirm(true);
    // const options = DialogService.confirmation(
    //     "ยืนยันยกเลิกการสร้างโพย",
    //     `คุณแน่ใจที่จะออกจากหน้าสร้างโพย ?`
    // );
    // DialogService.open(options, () => {
    //     closeDialog(dialogId);
    // });



    const columns: Column[] = [
        {
            field: 'orderNo', label: 'หมายเลขโพย', ...tableContentConfig.common,
            minWidth: 50,
        },

        {
            field: 'amount', label: 'ยอดแทง', ...tableContentConfig.number,
            minWidth: 150,
            render: (value: string) => formatToCurrencyString(value)
        },
        {
            field: 'orderDate', label: 'วันที่แทง', ...tableContentConfig.common,
            minWidth: 200,
            render: (value: string) => dayjs(value).format(DATE_FORMAT)
        },
        {
            field: 'actions', label: 'ตัวเลือก', ...tableContentConfig.action, minWidth: 150,
            render: (value: any) =>
                <Stack direction={'row'} width={180} spacing={2}>
                    <StyledFillButton sx={{ color: theme.palette.common.white, bgcolor: secondaryColor, ":hover": { color: theme.palette.secondary.light } }}
                        onClick={() => {
                            handleOpenDialog({ type: 'create-order' })
                        }}>แทงโพย</StyledFillButton>
                    <StyledFillButton sx={{ color: theme.palette.common.white, bgcolor: secondaryColor, ":hover": { color: theme.palette.secondary.light } }}
                        onClick={() => {
                            handleOpenDialog({ type: 'create-order' })
                        }}>ดูโพย</StyledFillButton>
                </Stack>
        },
    ];

    return (
        <StyledContainer>
            <PromptTypo variant="h5">รายการแทงหวย</PromptTypo>
            <Spacer size='md' />
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <Stack direction={'row'} alignItems={'center'}>
                    <PromptTypo sx={{ width: 'auto' }}>ค้นหา Agent</PromptTypo>
                    <Box sx={{ width: 280 }}>
                        <OdmntSelectSearch options={MOCK_AGENT_NAME_LIST} title={"ค้นหา Agent"} /></Box>
                    <Spacer size='xl' />
                    <PromptTypo sx={{ width: 80 }}>รหัส Agent</PromptTypo>
                    <TextField

                        placeholder='กรอกรหัส Agent'
                        sx={{ padding: '12px 12px', width: 280 }}
                        variant="outlined"
                        size="small"
                    />
                    <Spacer size='md' />
                    <StyledOutlineButton
                        disabled={false}
                        onClick={handleSearch}>
                        <PromptTypo>ค้นหา</PromptTypo>
                    </StyledOutlineButton>
                </Stack>
                {/* <StyledFillButton
                    sx={{ bgcolor: `${state.theme.palette.action.selected}`, ":hover": { color: theme.palette.secondary.light } }}
                    onClick={() => handleCreateGame()}>
                    เปิดงวดใหม่
                </StyledFillButton> */}
            </Stack>
            <Spacer size='md' />
            <PaginatedTable
                stickyActions={true}
                stickyHeader={false}
                showPagination={false}
                columns={columns}
                data={tableData as unknown as DataRow[] || []} />
        </StyledContainer>
    );
};

export default AgentOrderingPage;
