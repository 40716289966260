import React, { useState } from "react";
import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, Box, SpeedDial, SpeedDialIcon, SpeedDialAction } from "@mui/material";
import { Column } from "../table/hoverable_table_row";
import PromptTypo from "../typography/typography_prompt";
import LinesEllipsis from "react-lines-ellipsis";
import { useAppSettings } from "../../stores/context/app_setting_context";
import { SPACING_XS } from "../../configs/constants";
import { EmptyComponent } from "../blank/blank_table";
import Spacer from "../spacing/spacer";
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import { colorScheme } from "../theme/colors";

const actions = [
    { icon: <FileCopyIcon />, name: 'Copy' },
    { icon: <SaveIcon />, name: 'Save' },
    { icon: <PrintIcon />, name: 'Print' },
    { icon: <ShareIcon />, name: 'Share' },
];

interface PaginatedTableProps {
    columns: Column[];
    data: any[];
    count?: number;
    rowsPerPage?: number;
    page?: number;
    onPageChange?: (page: number) => void;
    tableHeight?: number;
    showPagination?: boolean;
    stickyHeader?: boolean;
    stickyActions?: boolean;
    sx?: any;
    showSpeedDeal?: boolean;
}

const PaginatedTable: React.FC<PaginatedTableProps> = ({
    columns = [],
    data = [],
    count = 0,
    rowsPerPage = 10,
    page = 0,
    onPageChange,
    tableHeight,
    showPagination = true,
    stickyHeader = true,
    showSpeedDeal = false,
    stickyActions = false,
    sx,

}) => {
    const { state } = useAppSettings();
    const [hoveredRowIndex, setHoveredRowIndex] = useState<number | null>(null); // Track hovered row index
    const tableColorStage = {
        defaultBg: state.theme.palette.action.active,
        tableContentBg: state.theme.palette.background.paper,
        tableEvenContentBg: state.theme.palette.action.active,
        stickyTopBg: `${state.theme.palette.info.main}30`,
        tableContentBgHovered: state.theme.palette.action.active,
        tableDefaultTextColor: state.theme.typography.body2.color,
        tableHighlightedTextColor: state.theme.palette.primary.main,
        tableActionMain: state.theme.palette.secondary.main,
        tableActionOptional: state.theme.palette.action.selected,
        hoverColor: `${colorScheme.bgColor}20`
    }
    return (
        <Paper sx={{ ...sx, width: '100%' }}>
            <TableContainer
                component={Paper}
                sx={{ height: `${tableHeight ?? 'auto'}${tableHeight && 'vh'}`, maxHeight: '75vh' }}
            >
                {data.length > 0 ? (
                    <Table padding="checkbox" size="small" aria-label="sticky table" stickyHeader={stickyHeader}>
                        <TableHead>
                            <TableRow
                                className="row_head"
                                sx={{
                                    height: '48px',
                                    paddingTop: '24px',
                                }}>
                                {columns.map((column) => (
                                    <TableCell key={column.id} align={column.align}>
                                        <PromptTypo variant="body2" fontWeight="bold">
                                            {column.label}
                                        </PromptTypo>
                                        <Spacer size="md" />
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row, index) => (
                                <TableRow
                                    key={index}
                                    sx={{
                                        backgroundColor: index % 2 === 0 ? `${state.theme.palette.action.active}15` : 'transparent',
                                        position: 'relative',
                                        zIndex: hoveredRowIndex === index ? 1 : 0,
                                    }}
                                    hover
                                    onMouseEnter={() => setHoveredRowIndex(index)}
                                    onMouseLeave={() => setHoveredRowIndex(null)}>
                                    {columns.map((column) => {
                                        const renderField = column?.render ? column.render(row[column.field]) : row[column.field] ?? '-';
                                        const ellipsisStyle = {
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            maxWidth: column.maxWidth ? `${column.maxWidth}px` : 'auto',
                                            minWidth: column.minWidth ? `${column.minWidth}px` : 'auto',
                                            width: column.width ? `${column.width}px` : 'auto',
                                            display: 'flex',
                                            alignItems: 'center',
                                        };
                                        const isStickyAction = column.field === 'actions' && !!column.isSticky;
                                        const additionalStickyStyle = column.isSticky ? stickyStyles('transparent', { right: 0 }) : {};
                                        const actionNomalBgColor = isStickyAction ? state.theme.palette.background.paper : ''
                                        const evenBgColor = index % 2 == 0 ? `${tableColorStage.tableActionMain}15` : '';
                                        const colActionBgColor = isStickyAction ? `${actionNomalBgColor}` : `${evenBgColor}`;
                                        return (
                                            <TableCell
                                                key={column.field}
                                                align={column.align}
                                                sx={{
                                                    ...additionalStickyStyle,
                                                    height: 75,
                                                    bgcolor: colActionBgColor,
                                                }}>
                                                <PromptTypo
                                                    variant="body2"
                                                    color={column.highlighten ? state.theme.palette.primary.main : state.theme.typography.body2.color}
                                                    fontWeight={column.highlighten ? 'bold' : 'normal'}>
                                                    <LinesEllipsis
                                                        style={{ ...ellipsisStyle, justifyContent: column.align ?? 'left', paddingLeft: SPACING_XS }}
                                                        text={renderField}
                                                        maxLine={0}
                                                        ellipsis="..."
                                                        trimRight
                                                        basedOn="letters"
                                                        component="p"
                                                    />
                                                </PromptTypo>
                                            </TableCell>
                                        );
                                    })}

                                    {hoveredRowIndex === index && showSpeedDeal && (
                                        <Box
                                            sx={{
                                                position: 'absolute', bottom: 16, right: 16,
                                                bgcolor: 'red'
                                            }}>
                                            <SpeedDial
                                                ariaLabel="SpeedDial example"
                                                icon={<SpeedDialIcon />}
                                                direction="left"
                                            >
                                                {actions.map((action) => (
                                                    <SpeedDialAction
                                                        key={action.name}
                                                        icon={action.icon}
                                                        tooltipTitle={action.name}
                                                        onClick={() => console.log(`${action.name} clicked`)}
                                                    />
                                                ))}
                                            </SpeedDial>
                                        </Box>
                                    )}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                ) : (
                    <EmptyComponent displayedText="No data available" />
                )}
            </TableContainer>
            {
                showPagination && (
                    <TablePagination
                        rowsPerPageOptions={[10]}
                        component="div"
                        count={+count}
                        rowsPerPage={+rowsPerPage}
                        page={+page - 1}
                        onPageChange={(e, newPage) => onPageChange?.(+newPage + 1)}
                    />
                )
            }
        </Paper >
    );
};

export const stickyStyles = (bgColor: string, { left = 0, right = 0 }) => {
    return {
        position: "sticky",
        overflow: 'hidden',
        zIndex: 9,
        left: left,
        right: right,
        background: `${bgColor}`,
        padding: "8px",
        fontWeight: 'bold'
    }
}

export default PaginatedTable;
