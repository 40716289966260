import React, { useState } from "react";
import { saveAs } from "file-saver";
import excelService from "../../services/excel_service"; // Ensure correct path to your service
import { FileDownloadSharp } from "@mui/icons-material";
import { StyledTextButton } from "../../styled/filled_button";
import Spacer from "../spacing/spacer";
import PromptTypo from "../typography/typography_prompt";
import { SwalAlert, SwalCompleted } from "../dialog/swal_alert";
import { formatDateTime } from "../../helpers/date_time_helper";
import dayjs from "dayjs";

interface ExcelGeneratorProps {
    fetchReportData?: () => Promise<any>; // Function to fetch data for the report
    data?: any[],
    columns: { label: string; field: string; width?: number }[];
    fileName: string;
}

const ExcelGenerator: React.FC<ExcelGeneratorProps> = ({ columns, fetchReportData, fileName, data }) => {
    const [reportData, setReportData] = useState<any[]>([]); // State to store fetched data
    const unixTimeString = dayjs(new Date()).unix();
    const handleDownloadExcel = async () => {
        try {
            let fetchedData = [];

            if (fetchReportData) {
                // Wait for fetchReportData to return the data
                console.log('Fetching report data...');
                fetchedData = await fetchReportData();
            } else if (data && data.length > 0) {
                // If fetchReportData is not available, fallback to using `data`
                fetchedData = data;
            }

            if (fetchedData && fetchedData.length > 0) {
                // No need to call setReportData multiple times, update it once
                setReportData(fetchedData);

                // Process the data without "actions"
                const reportWithoutActionData = fetchedData.map((e: any) => {
                    const { actions, ...rest } = e; // Remove actions field
                    return rest;
                });

                // Wait for the column mapping and worksheet addition to complete
                const worksheetColumns = await excelService.mapColumns(columns);

                // Add worksheet to the Excel file (with report data)
                await excelService.addWorksheet(`ws-${unixTimeString}`, worksheetColumns, reportWithoutActionData);

                // Generate the Excel file as a buffer
                const buffer = await excelService.generateExcelBuffer();

                // Create a Blob from the buffer and trigger the download
                const blob = new Blob([buffer], { type: "application/octet-stream" });
                saveAs(blob, `${fileName}.xlsx`);

                // Clean up ExcelService after the process is complete
                excelService.cleanup();
                SwalCompleted();
            } else {
                // If no data found, reset the report data
                setReportData([]);
                SwalAlert({
                    title: "ทำรายการไม่สำเร็จ",
                    description: "กรุณาลองใหม่อีกครั้งในภายหลัง"
                });
            }

        } catch (error) {
            console.error("Error generating the report", error);
            SwalAlert({
                title: "ทำรายการไม่สำเร็จ",
                description: `${error}`
            });
        }
    };


    return (
        <StyledTextButton onClick={handleDownloadExcel}>
            <FileDownloadSharp />
            <Spacer size="md" />
            <PromptTypo variant="h6">ดาวน์โหลดรายงาน</PromptTypo>
        </StyledTextButton>
    );
};

export default ExcelGenerator;