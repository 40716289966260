import { Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { AgentRateDetails } from '../../services/rate_service';
import { StyledFillButton } from '../../styled/filled_button';
import Spacer from '../spacing/spacer';
import { colorScheme } from '../theme/colors';
import PromptTypo from '../typography/typography_prompt';


const formInitialData = {
    threeUpperStraightPrice: 0,
    threeUpperStraightPercent: 0,
    threeShufflePrice: 0,
    threeShufflePercent: 0,
    twoUpperStraightPrice: 0,
    twoUpperStraightPercent: 0,
    twoShufflePrice: 0,
    twoShufflePercent: 0,
    upperRunningPrice: 0,
    upperRunningPercent: 0,
    threeLowerStraightPrice: 0,
    threeLowerStraightPercent: 0,
    twoLowerStraightPrice: 0,
    twoLowerStraightPercent: 0,
    lowerRunningPrice: 0,
    lowerRunningPercent: 0,
    fourFloatingPrice: 0,
    fourFloatingPercent: 0,
    fiveFloatingPrice: 0,
    fiveFloatingPercent: 0,
}

interface RateAdjustmentFormProps {
    mode: 'EDIT' | 'COPY';
    selectedAgentId: number | string;
    data?: AgentRateDetails;
    onSubmit?: (state: Partial<AgentRateDetails>) => void;
    onClose?: () => void;

}

const RateAdjustmentForm: React.FC<RateAdjustmentFormProps> = ({ mode, selectedAgentId, data = {}, onSubmit, onClose }) => {
    const [agentId, setAgentId] = useState<string | number>(selectedAgentId);
    const [fields, setFields] = useState<Partial<AgentRateDetails>>(formInitialData);
    const newData = JSON.parse(JSON.stringify(data));

    delete newData.id;
    delete newData.agentId;
    delete newData.agentCode;
    delete newData.agentFirstname;
    delete newData.agentLastname;

    const mapField = mapAgentRateDetailsToMapping();

    const handleInputChange = (updatedField: Partial<AgentRateDetails>) => {
        setFields((prevFields) => ({
            ...prevFields,
            ...updatedField,
        }));
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        onSubmit?.(fields);
    };

    useEffect(() => {
        if (!selectedAgentId) {
            return;
        }
        setAgentId(selectedAgentId);
        setFields(newData);
    }, [])

    function mapAgentRateDetailsToMapping(): {
        label: string;
        prefixKey: string;
        field: { price: keyof AgentRateDetails; percent: keyof AgentRateDetails };
    }[] {
        return [
            { label: "3 บน", prefixKey: 'threeUpperStraight', field: { price: 'threeUpperStraightPrice', percent: 'threeUpperStraightPercent' } },
            { label: "3 โต๊ด", prefixKey: 'threeShuffle', field: { price: 'threeShufflePrice', percent: 'threeShufflePercent' } },
            { label: "2 บน", prefixKey: 'twoUpperStraight', field: { price: 'twoUpperStraightPrice', percent: 'twoUpperStraightPercent' } },
            { label: "2 โต๊ด", prefixKey: 'twoShuffle', field: { price: 'twoShufflePrice', percent: 'twoShufflePercent' } },
            { label: "วิ่ง บน", prefixKey: 'upperRunning', field: { price: 'upperRunningPrice', percent: 'upperRunningPercent' } },
            { label: "3 ล่าง", prefixKey: 'threeLowerStraight', field: { price: 'threeLowerStraightPrice', percent: 'threeLowerStraightPercent' } },
            { label: "2 ล่าง", prefixKey: 'twoLowerStraight', field: { price: 'twoLowerStraightPrice', percent: 'twoLowerStraightPercent' } },
            { label: "วิ่ง ล่าง", prefixKey: 'lowerRunning', field: { price: 'lowerRunningPrice', percent: 'lowerRunningPercent' } },
            { label: "ลอยแพ 4 ตัว", prefixKey: 'fourFloating', field: { price: 'fourFloatingPrice', percent: 'fourFloatingPercent' } },
            { label: "ลอยแพ 5 ตัว", prefixKey: 'fiveFloating', field: { price: 'fiveFloatingPrice', percent: 'fiveFloatingPercent' } },
        ];
    }

    return (
        <form onSubmit={handleSubmit}>
            <TableContainer
                component={Paper}
                sx={{
                    width: 'auto',
                    maxHeight: '50vh',
                    overflow: 'auto',
                }}
            >
                <Table size='small' stickyHeader>
                    <TableHead>
                        <TableRow sx={{ height: '50px' }}>
                            <TableCell>
                                <PromptTypo variant='subtitle1' sx={{ fontWeight: 'bold' }}>
                                    รายการ
                                </PromptTypo>
                            </TableCell>
                            <TableCell align="center">
                                <PromptTypo variant='subtitle1' sx={{ fontWeight: 'bold' }}>
                                    ค่าใช้จ่าย
                                </PromptTypo>
                            </TableCell>
                            <TableCell align="center">
                                <PromptTypo variant='subtitle1' sx={{ fontWeight: 'bold' }}>
                                    ส่วนลด
                                </PromptTypo>
                            </TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(mapField?.length !== 0) && mapField?.map((fieldItem, index) => {
                            const { price, percent } = fieldItem.field;
                            return <TableRow
                                sx={{
                                    backgroundColor: index % 2 === 0 ? `${colorScheme.instructionBlue}12` : 'transparent',
                                }}
                                key={fieldItem.prefixKey}
                            >
                                <TableCell size="small">{fieldItem.label}</TableCell>
                                <TableCell sx={{ verticalAlign: 'middle' }} align="center">
                                    <TextField
                                        type="text"
                                        sx={{ width: '100%', height: '37px', marginBottom: '18px' }}
                                        placeholder="จ่าย"
                                        value={fields[price] ?? ''}
                                        onChange={(e) => {
                                            let input = e.target.value;
                                            if (input.length > 1 && input.startsWith('0')) {
                                                input = input.slice(1);
                                            }

                                            let newValue = parseFloat(input) || 0;
                                            newValue = Math.max(0, Math.min(newValue, 1000));
                                            handleInputChange({ ...fields, [price]: newValue });
                                        }}
                                    />
                                </TableCell>
                                <TableCell sx={{ verticalAlign: 'middle' }} align="center">
                                    <TextField
                                        type="text"
                                        sx={{ width: '100%', height: '37px', marginBottom: '18px' }}
                                        placeholder="ส่วนลด"
                                        value={fields[percent] ?? ''}
                                        onChange={(e) => {
                                            let input = e.target.value;
                                            if (input.length > 1 && input.startsWith('0')) {
                                                input = input.slice(1);
                                            }

                                            let newValue = parseFloat(input) || 0;
                                            newValue = Math.max(0, Math.min(newValue, 100));
                                            handleInputChange({ ...fields, [percent]: newValue });
                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                        }

                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Paper sx={{ width: '100%' }}>
                <TableHead>
                    <TableRow>
                        <TableCell
                            sx={{ mt: 2 }}>
                            <Stack direction='row' sx={{ height: '40px', padding: '4px 12px', justifyContent: 'flex-end', width: '100%' }}>
                                <StyledFillButton type='submit'>
                                    บันทึก
                                </StyledFillButton>
                                <Spacer size='md' />
                                <StyledFillButton type='button' sx={{ bgcolor: 'transparent' }}
                                    onClick={() => onClose?.()}>
                                    ยกเลิก
                                </StyledFillButton>

                            </Stack>

                        </TableCell>
                    </TableRow>
                </TableHead>
            </Paper>
        </form >
    );
};

export default RateAdjustmentForm;
