import Swal, { SweetAlertOptions } from "sweetalert2";
import loadingBottlesGif from '../../assets/images/loading_bottles.gif';
import loadingLabGif from '../../assets/images/loading_lab2.gif';
import loadingInfGif from '../../assets/images/loading_inf3.gif';
import loadingCircleGif from '../../assets/images/loading_circle2.gif';
import nyanCatGif from '../../assets/images/nyan-cat.gif';
import kamehamehaGif from '../../assets/images/kamehameha.gif';
import '../../App.css';

const loadingReplacedButton: HTMLButtonElement | null = null;

export const SwalAlert = ({ title = '', description = '' }) => Swal.fire({
  icon: "error",
  title: title,
  text: description,
  allowOutsideClick: false,
});

export const SwalLoadingSpecial = () => {
  return Swal.fire({
    padding: "8rem 3rem",
    heightAuto: true,
    background: `transparent`,
    showConfirmButton: false,
    allowOutsideClick: false,
    showCancelButton: false,
    backdrop: `
    #020f1485
    url("${kamehamehaGif}")
    center
    no-repeat
  `
  })
};

export const SwalLoadingSpecial2 = () => {
  return Swal.fire({
    padding: "8rem 3rem",
    heightAuto: true,
    background: `transparent`,
    showConfirmButton: false,
    allowOutsideClick: false,
    showCancelButton: false,
    backdrop: `
    #020f1485
    url("${loadingCircleGif}")
    center
    no-repeat
  `
  })
}

export const SwalLoading = () => {
  return Swal.fire({
    title: "กำลังดำเนินการ",
    didOpen: () => {
      Swal.showLoading();
    },
    allowOutsideClick: false,
    backdrop: true,
  });
}

export const SwalCompleted = () => {
  return Swal.fire({
    title: "ดำเนินการสำเร็จ",
    icon: "success",
    allowOutsideClick: true,
    backdrop: true,
    showConfirmButton: true,
    background: ``,
  });
}

export const createdUserSuccessfully = () => {
  return Swal.fire({
    position: "center",
    icon: "success",
    title: "สร้างสมาชิกสำเร็จ",
    showConfirmButton: false,
    timer: 1500
  });
}
export const createdUserfail = (error: string) => {
  return Swal.fire({
    position: "center",
    icon: "error",
    title: 'ทำรายการไม่สำเร็จ',
    text: error,
    showConfirmButton: true,
  });
}

export const SwalLeaveConfirm = (yes?: () => any, no?: () => any, options?: SweetAlertOptions) => {
  Swal.fire({
    title: "Are you sure you want to leave?",
    text: "Any unsaved changes will be lost.",
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Yes, leave",
    cancelButtonText: "No, stay",
    position: 'top',
    backdrop: true,
    allowOutsideClick: false,
    customClass: {
      container: 'put-to-top'
    },
    ...options
  }).then((result) => {
    if (result.isConfirmed) {
      yes?.();
    }
    else {
      no?.();
    }
  });
};