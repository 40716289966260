import React, { useState, useRef, forwardRef, useEffect } from "react";
import { Box, Button, Container, Divider, FormControl, FormControlLabel, IconButton, Input, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Snackbar, Stack, Switch, SxProps, TextFieldProps } from '@mui/material';
import PromptTypo from '../typography/typography_prompt';
import StyledTextField from '../styled/styled_textfield';
import { StyledFillButton, StyledOutlineButton, StyledTextButton } from '../../styled/filled_button';
import AppDatePicker from "../button/date_picker_button";
import SwalDatePicker from "../dialog/swal_date_picker";
import dayjs from "dayjs";
import { generalDateFormat, generalDateTimeFormat } from "../../configs/constants";
import { hasPermission } from "../../configs/permission";
import { useAuth } from "../../stores/providers/auth_provider";
import { DatasetLinked, DateRange, KeyboardArrowDown, KeyboardArrowUp, PanoramaFishEye, RamenDiningOutlined, SwipeVerticalSharp, TodayRounded, VerticalAlignBottomOutlined, VerticalAlignTop, Visibility, VisibilityOff } from "@mui/icons-material";
import AppReactSelector, { SelectorItem } from "./react_selector";
import DatePicker from "react-datepicker";
import Spacer from "../spacing/spacer";
export interface AppFormData {
    name: string;
    value: string;
    pattern?: RegExp;
    error?: boolean;
    required?: boolean;
    placeholder?: string;
    label?: string;
    inputType?: string;
    disabled?: boolean;
    choices?: Choice[];
    minValue?: string;
    maxValue?: string;
    textFieldProps?: TextFieldProps | undefined;
    requiredPermission?: string;
    isClearable?: boolean;
    checked?: boolean;
    sx?: SxProps;
}

export interface Choice {
    label: string, value: string, requiredPermission?: string
}

interface FormBuilderProps {
    fields: AppFormData[];
    formTitle?: string;
    formSubTitle?: string;
    onSubmit?: (data: Record<string, string | number>) => void;
    onChange?: (data: Record<string, string | number>) => void;
    onClearData?: () => void;
    onCancel?: () => void;
    sx?: SxProps;
    textFieldProps?: TextFieldProps | undefined;
    col?: number;  // Add col property
    itemsPerRow?: number;
    marginTop?: number;
    padding?: string;
    children?: JSX.Element;
    containerSize?: number;
    showSubmitButton?: boolean;
}

const FormBuilder: React.FC<FormBuilderProps> = ({
    formTitle,
    fields,
    onSubmit,
    onClearData,
    onCancel,
    onChange,
    sx,
    textFieldProps,
    formSubTitle,
    itemsPerRow,
    children,
    containerSize,
    marginTop,
    padding,
    showSubmitButton = true
}) => {
    const [formData, setFormData] = useState<AppFormData[]>(fields);
    const [errors, setErrors] = useState<{ [key: string]: boolean }>({});
    const [isFormValid, setIsFormValid] = useState<boolean>(true);
    const [initialFormData, setInitialFormData] = useState<AppFormData[]>();
    const { user } = useAuth();
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [startDate, setStartDate] = useState<Date | null | undefined>();
    const [endDate, setEndDate] = useState<Date | null | undefined>();

    useEffect(() => {
        setInitialFormData(fields);
    }, [])

    useEffect(() => {
        const emptyRequiredField = fields.find(f => (f.required && (f.value == '' || !f.value)));
        setFormData(fields);
        setIsFormValid(!emptyRequiredField)
    }, [fields]);


    const mapFormDataToRecord = (formFields: AppFormData[]): Record<string, string | number> => {
        const data: Record<string, string | number> = {};
        formFields.forEach(field => {
            data[field.name] = field.value;
        });

        return data;
    };

    const handleChange = (
        name: string,
        value: string,
        checked = false,
    ) => {
        const updatedFormData = formData.map((field) => {
            return field.name === name ? { ...field, value, checked } : field;
        });
        validateField(name, value);
        setFormData(updatedFormData);
        const formDataObject = mapFormDataToRecord(updatedFormData);
        onChange?.(formDataObject);
        console.log('updatedFormData', updatedFormData);
        console.log('formDataObject', formDataObject);

    };

    const validateField = (name: string, value: string) => {
        const field = formData.find(f => f.name === name);
        let hasError = false;
        let tempStringValue = `${value ?? ''}`
        if (field?.required && !tempStringValue?.trim()) {
            hasError = true;
        } else if (field?.pattern && !field.pattern.test(value)) {
            hasError = true;
        }
        setErrors((prev) => ({ ...prev, [name]: hasError }));
    };

    const handleSubmit = () => {
        const submittedData = formData.reduce((acc, field) => {
            acc[field.name] = field.value;
            return acc;
        }, {} as Record<string, string | number>);


        onSubmit?.(submittedData);
    };

    const forcedPutValueOnUnfocused = (name: string, value: string) => {
        const field = formData.find(f => f.name === name);
        let newValue: string = '';
        if (field?.minValue && !(+field.value)) handleChange(name, field.minValue);
        else if (field?.minValue && (+field.value) < (+field.minValue))
            newValue = field.minValue;
        !!newValue.length && handleChange(name, newValue)
    }

    useEffect(() => {
        const someFieldHasError = Object.keys(errors).some((key) => errors[key] === true);
        const someFieldIsEmptyInitial = formData.some(field => field.required === true && field.value == '');
        if (Object.keys(errors).some((key) => errors[key] === true)) setIsFormValid(false);
        else if (formData.some(field => field.required === true && field.value == '')) setIsFormValid(false);
        else setIsFormValid(true);

    }, [formData]);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleMouseUpPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const [isSelectOpen, setIsSelectOpen] = useState(false);

    const renderField = (field: AppFormData) => {
        switch (field.inputType) {
            case 'select':
                return (
                    <Select
                        value={field.value}
                        onChange={(e) => handleChange(field.name, e.target.value)}
                        disabled={field.disabled}
                        size="small"
                        style={{ width: '100%', height: '48px' }}
                        sx={sx}
                        IconComponent={() =>
                            isSelectOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />
                        }
                        onOpen={() => setIsSelectOpen(true)}
                        onClose={() => setIsSelectOpen(false)}
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    zIndex: 1300, // Ensure this matches or is higher than your dialog's z-index
                                },
                            },
                            disablePortal: true,
                        }}
                    >
                        {
                            field.choices?.map(choice => {
                                const visible = (!!!choice.requiredPermission) || hasPermission(user.permissions, choice.requiredPermission)
                                return visible && <MenuItem key={choice.value} value={choice.value} >
                                    {choice.label}
                                </MenuItem>
                            })
                        }
                    </Select >
                );

            case 'select-2':
                return (
                    <AppReactSelector
                        placeholder={field.placeholder ?? field.name}
                        defaultValue={{ label: field.label ?? '', value: field.value ?? '' }}
                        options={
                            field.choices?.map(choice => {
                                const visible = (!!!choice.requiredPermission) || hasPermission(user.permissions, choice.requiredPermission)
                                return visible ? { value: choice.value, label: choice.label } : undefined;
                            }).filter(Boolean) as SelectorItem[]}
                        onChange={(e: any) => {
                            handleChange(field.name, e.value)
                        }}
                    />
                );


            case 'dateTime':
                return (
                    <AppDatePicker
                        showTimeInput
                        // style={{ width: "100%" }}
                        name={field.name}
                        placeholder={field.placeholder ?? field.name}
                        value={null}
                        error={field.error ?? false}
                        isClearable={false}
                        onChange={(date) => {
                            handleChange(field.name, dayjs(date).format())
                        }}
                    />
                );

            case 'dateTimePopup':
                return (
                    <AppDatePicker
                        showTimeInput
                        name={field.name}
                        withPortal={false}
                        placeholder={field.placeholder ?? field.name}
                        value={null}
                        error={field.error ?? false}
                        isClearable={false}
                        onChange={(date) => {
                            handleChange(field.name, dayjs(date).format())
                        }}
                    />
                );

            case 'date':
                return (
                    <AppDatePicker
                        sx={{ ...field.sx }}
                        style={{ width: "100%" }}
                        name={field.name}
                        placeholder={field.placeholder ?? field.name}
                        value={null}
                        error={field.error ?? false}
                        isClearable={true}
                        showTimeInput={false}
                        onChange={(date) => {
                            handleChange(field.name, dayjs(date).format())
                        }}
                    />
                );
            case 'date-range':
                return customDateRangePicker();

            case 'password':
                return <OutlinedInput
                    sx={{ ...field.sx }}
                    id="password"
                    fullWidth
                    placeholder={field.placeholder ?? field.name}
                    type={showPassword ? 'text' : field.inputType}
                    error={errors[field.name] || false}
                    onChange={(e) => handleChange(field.name, e.target.value)}
                    value={field.value}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label={
                                    showPassword ? 'hide the password' : 'display the password'
                                }
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                onMouseUp={handleMouseUpPassword}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                />

            case 'spacer':
                return (
                    <></>
                );

            case 'toggle':
                return (
                    <FormControlLabel
                        sx={{ ...field.sx }}
                        control={
                            <Switch
                                checked={field.checked ?? false}
                                onChange={(_: any, checked: boolean) => handleChange(field.name, `${checked ?? false}`, checked)}
                                disabled={field.disabled}
                                color="info"
                            />
                        }
                        label={field.label ?? 'Toggle'}
                        labelPlacement="end"
                    />
                );

            default:
                return (
                    <StyledTextField
                        name={field.name}
                        placeholder={field.placeholder ?? ''}
                        onChange={(e) => handleChange(field.name, e.target.value)}
                        error={errors[field.name] || false}
                        value={field.value}
                        props={field.textFieldProps}
                        disabled={field.disabled}
                        type={field.inputType}
                        onBlur={field.minValue ? () => forcedPutValueOnUnfocused(field.name, field.value) : undefined}
                        sx={{ ...field.sx }}
                    />
                );
        }
    }

    const customDateRangePicker = () => {
        return (
            <Stack direction={'row'}>
                <Box>
                    <PromptTypo textAlign={'right'}>
                        เริ่มต้น
                    </PromptTypo>
                    <DatePicker
                        selected={startDate}
                        onChange={(date) => {
                            setStartDate(dayjs(date).toDate());
                            handleSubmit();
                        }}
                        selectsStart
                        startDate={startDate ?? undefined}
                        endDate={endDate ?? undefined}
                        withPortal
                        customInput={
                            <StyledTextButton>
                                <DateRange />
                                <Spacer size="xs" />
                                <PromptTypo variant="h5">
                                    {startDate ? dayjs(startDate).format(generalDateFormat) : 'เริ่มต้น'}
                                </PromptTypo>

                            </StyledTextButton>}
                    />
                </Box>
                <Spacer size='lg' />
                <Box>
                    <PromptTypo textAlign={'right'}>
                        สิ้นสุด
                    </PromptTypo>
                    <DatePicker
                        selected={endDate}
                        onChange={(date) => {
                            setEndDate(dayjs(date).toDate());
                            handleSubmit();
                        }}
                        selectsEnd
                        startDate={startDate ?? undefined}
                        endDate={endDate ?? undefined}
                        minDate={startDate ?? undefined}
                        withPortal
                        customInput={
                            <StyledTextButton>
                                <DateRange />
                                <Spacer size="xs" />
                                <PromptTypo variant="h5">
                                    {endDate ? dayjs(endDate).format(generalDateFormat) : 'สิ้นสุด'}
                                </PromptTypo>
                            </StyledTextButton>}
                    />
                </Box>
            </Stack>
        );
    };

    return (
        <Container sx={sx ?? {}} maxWidth="lg" style={{ display: 'flex', width: containerSize ?? 640, marginTop: `${marginTop ?? '1rem'}`, padding: padding ?? '16px 32px' }}>
            <form onSubmit={() => {
                return
            }}>
                <Divider>
                    <PromptTypo fontWeight={'bold'} variant="h5" gutterBottom={formSubTitle ? false : true}>
                        {formTitle ?? ''}
                    </PromptTypo>
                    <PromptTypo variant="h6" gutterBottom color="info">
                        {formSubTitle ?? ''}
                    </PromptTypo>
                </Divider>
                <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                    {formData.map((field, index) => (
                        <div
                            key={index}
                            style={{
                                width: itemsPerRow ? `${100 / (itemsPerRow ?? 1)}%` : '100%',
                                padding: '5px'
                            }}
                        >
                            <PromptTypo marginY={0.5}>
                                {`${field.label ?? ''}`}
                                <span style={{ color: '#FF5C00' }}>
                                    {`${field.required ? '*' : ''}`}
                                </span>
                            </PromptTypo>
                            {renderField(field)}
                            {errors[field.name] && (
                                <PromptTypo fontSize={12} color="error" className="p-0">
                                    กรุณากรอกข้อมูลให้ถูกต้อง
                                </PromptTypo>
                            )}
                        </div>
                    ))}
                </div>
                {children}
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '2rem', marginBottom: '2rem' }}>
                    {showSubmitButton && <StyledFillButton
                        disabled={
                            Object.keys(errors).some((key) => errors[key] === true) ||
                            formData.some((field) => field.required && field.value == ''
                            )
                        }
                        variant="contained" type="button" style={{ padding: '4px 48px' }}
                        onClick={handleSubmit}
                    >
                        <PromptTypo sx={{ padding: '4px 6px' }} variant="body1">
                            ยืนยัน
                        </PromptTypo>
                    </StyledFillButton>}

                    {onClearData && (
                        <StyledFillButton sx={{ padding: '4px 12px' }} color="info" size="small" onClick={() => { onClearData() }}>
                            <PromptTypo variant="body1">
                                ล้างข้อมูล
                            </PromptTypo>
                        </StyledFillButton>
                    )}
                    {onCancel && (
                        <StyledTextButton sx={{ padding: '4px 12px' }} variant="outlined" color="secondary" size="small" onClick={onCancel}>
                            <PromptTypo variant="body1">
                                ยกเลิก
                            </PromptTypo>
                        </StyledTextButton>
                    )}
                </div>
            </form>
        </Container >
    );
};

export default FormBuilder;
