// EditProfileForm.tsx
import React from 'react';
import FormBuilder, { AppFormData } from '../form/form_builder';
import { StyledContainer } from '../styled/styled_flex_container';
import { Box } from '@mui/material';

const formFields: AppFormData[] = [
    { name: 'name', value: '', required: true, placeholder: 'ชื่อ', label: 'ชื่อ', sx: { width: '500px' } },
    { name: 'phone', value: '', pattern: /^\d{10}$/, required: true, placeholder: 'เบอร์โทรศัพท์', label: 'เบอร์โทรศัพท์', sx: { width: '500px' } },
    { name: 'email', value: '', pattern: /\S+@\S+\.\S+/, required: true, placeholder: 'อีเมล', label: 'อีเมล', sx: { width: '500px' } },
];

const EditProfileForm: React.FC = () => {
    const handleFormSubmit = (data: Record<string, string | number>) => {
        console.log('Form submitted:', data);
    };

    return (
        <FormBuilder sx={{ paddingRight: '300px' }} formTitle='ช้อมูลส่วนตัว' itemsPerRow={1} fields={formFields} onSubmit={handleFormSubmit} />
    );
};

export default EditProfileForm;
