// EditProfileForm.tsx
import React, { useEffect, useState } from 'react';
import { userDefaultCreateFormFields } from "../../configs/constants";
import { User } from "../../services/auth_service";
import { useAuth } from '../../stores/providers/auth_provider';
import FormBuilder, { AppFormData } from '../form/form_builder';

const formFields: AppFormData[] = userDefaultCreateFormFields;

export type CreateUserFormProps = {
    user?: User
    userType?: string
    onSubmit?: (formData: Record<string, string | number>) => void | Promise<void> | undefined | null
};

const CreateUserForm: React.FC<CreateUserFormProps> = ({ user, onSubmit }) => {
    const [fields, setFields] = useState<AppFormData[]>([...formFields]);
    const { user: currentUser } = useAuth();
    useEffect(() => {
        if (user) {
            const filledFields = fields.map((field) => ({
                ...field,
                value: user[field.name],
                disabled: ["id", "role"].includes(field.name),
                required: field.name === "password" ? false : field.required, // ถ้าแก้ไข ก็คือจะไม่สนใจว่าใส่พาสเวิร์ดหรือไม่.. ถ้าใส่มาคือแก้ไขรหัสผ่าน
            }));
            setFields(filledFields);
        }
        else {
            // const _mockValue = fields.map((field) => ({
            //     ...field,
            //     value: generateRandomValue(field), // Generate value based on field type
            // }));
            // console.log({ mock: _mockValue });
            // setFields(_mockValue);
        }

    }, [user])

    const handleFormSubmit = (data: Record<string, string | number>) => {
        const newValue = assignNullIfEmptyValue(data);
        const payload = {
            ...newValue,
            firstName: newValue.firstName ?? '',
            lastName: newValue.lastName ?? '',
            email: newValue.email ?? '',
            phone: newValue.phone ?? '',
            ownerId: currentUser.ownerId ?? currentUser.id
        }
        if (onSubmit) onSubmit(payload);
    };

    const assignNullIfEmptyValue = (data: Record<string, string | number | null | undefined>) => {
        return Object.fromEntries(
            Object.entries(data).map(([key, value]) => [key, value === '' ? null : value])
        );
    };

    return (
        <FormBuilder
            formTitle={user ? "แก้ไขสมาชิก" : "เพิ่มสมาชิก"}
            fields={fields} onSubmit={handleFormSubmit} />
    );
};

export default CreateUserForm;
