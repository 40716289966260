import React, { useState } from 'react';
import { Popover, Button, Typography, PopoverProps, SxProps, Paper, useTheme } from '@mui/material';
import { StyledFillButton } from '../../styled/filled_button';
import { Stack } from 'rsuite';
import PromptTypo from '../typography/typography_prompt';
import Spacer from '../spacing/spacer';

interface CustomPopOverProps extends PopoverProps {
    triggerText: string,
    popOverContent: React.ReactNode,
    confirmButton?: React.ReactNode,
    sx?: SxProps
}

const CustomPopOver: React.FC<CustomPopOverProps> = (props) => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl); // Check if the Popover is open
    const id = open ? 'simple-popover' : undefined; // Accessibility id

    return (
        <div>

            <StyledFillButton
                onClick={handleClick}
                fullWidth
                sx={{ bgcolor: 'secondary.main', maxWidth: '300px', ...props.sx }}>
                {props.triggerText}
            </StyledFillButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Paper sx={{ padding: '16px 32px', bgcolor: theme.palette.background.default }}>
                    <Stack>
                        <PromptTypo>{props.triggerText}</PromptTypo>
                        <Spacer size='md' />
                        {props.popOverContent}
                        <Spacer size='md' />
                        {props.confirmButton}
                    </Stack>
                </Paper>
            </Popover>
        </div>
    );
};

export default CustomPopOver;
