import React from 'react';
import FormBuilder, { AppFormData } from '../form/form_builder';

const formFields: AppFormData[] = [
    {
        name: 'currentPassword', value: '', required: true, placeholder: 'รหัสผ่านปัจจุบัน', label: 'รหัสผ่านปัจจุบัน',
        textFieldProps: { type: 'password', name: '', autoComplete: '', sx: { width: '500px' } }
    },
    {
        name: 'newPassword', value: '', required: true, placeholder: 'รหัสผ่านใหม่', label: 'รหัสผ่านใหม่',
        textFieldProps: { type: 'password', name: '', autoComplete: '', sx: { width: '500px' } }
    },
    {
        name: 'confirmNewPassword', value: '', required: true, placeholder: 'ยืนยันรหัสผ่านใหม่', label: 'ยืนยันรหัสผ่านใหม่',
        textFieldProps: { type: 'password', name: '', autoComplete: '', sx: { width: '500px' } }
    },
];

const ChangePasswordForm: React.FC = () => {
    const handleFormSubmit = (data: Record<string, string | number>) => {
        console.log('Form submitted:', data);
    };

    return (
        <FormBuilder formTitle='เปลี่ยนรหัสผ่านผู้ใช้' fields={formFields} onSubmit={handleFormSubmit} />
    );
};

export default ChangePasswordForm;
