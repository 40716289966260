import React from 'react'
import LinesEllipsis from 'react-lines-ellipsis'

function TableContentEllipsisText({ text = '', style = {} }) {
    const ellipsisStyle = {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '300px',
        display: 'flex',
        alignItems: 'center',
    };

    return (
        <LinesEllipsis
            style={{ ...ellipsisStyle, ...style }}
            text={text}
            maxLine={0}
            ellipsis='...'
            trimRight
            basedOn='letters'
            component='p'
        />
    )
}

export default TableContentEllipsisText
