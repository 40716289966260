export interface TranslationMapType {
    [key: string]: string | TranslationMapType; // Keys can be strings, and values can be strings or nested TranslationMapType objects
}

export const translationMap: TranslationMapType = {
    common: {},
    input: {
        'INPUT_SELECT_ALL': 'ทั้งหมด',
    },
    userStatus: {
        'ACTIVE': 'ใช้งาน',
        'DISABLED': 'ปิดใช้งาน',
    },
    creditStatus: {
        'REQUEST': 'กำลังดำเนินการ',
        'APPROVED': 'อนุมัติแล้ว',
        'REJECTED': 'ปฏิเสธแล้ว',
    }
};