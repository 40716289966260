import { Check, Close } from "@mui/icons-material";
import { Stack } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Alignment from "../components/alignment";
import SingleDatePicker from "../components/datepicker/single_date_picker";
import DialogService from "../components/dialog/confirmation_by_sweet_alert2";
import { SwalCompleted } from "../components/dialog/swal_alert";
import AppPagination from "../components/pagination/app_pagination";
import Spacer from "../components/spacing/spacer";
import AppFloatingSpeedDeal from "../components/speed_deal/speed_deal";
import { StyledContainer } from "../components/styled/styled_flex_container";
import { Column, DataRow } from "../components/table/hoverable_table_row";
import { CREDIT_STATUS, CreditRequestFilterOptions } from "../components/table_filters/credit_table_filter";
import PaginatedTable from "../components/template/pagination_table_page";
import PromptTypo from "../components/typography/typography_prompt";
import { SPACING_MD, statusTableDisplayMap, tableContentConfig } from "../configs/constants";
import { convertISOStringtoFormattedDateTime } from "../helpers/date_time_helper";
import { formatToCurrencyString } from "../helpers/number_formatter_helper";
import creditService, { CreditRequestData, CreditRequestParams, CreditSourceType, CreditStatusFilter } from "../services/credit_service";
import { useAppSettings } from "../stores/context/app_setting_context";
import { useDialog } from "../stores/providers/dialog_provider";
import StyledSelect from "../styled/styled_select";
import { useAuth } from "../stores/providers/auth_provider";
import { renderValueIfNotEmpty } from "../configs/functions";

interface TopUpRequestPageProps { }

const defaultCreditBalanceFilter = {
    type: CreditSourceType.TOP_UP,
    status: CreditStatusFilter.ALL,
    page: 1,
    perPage: 10
}

export const statusDisplayMap: Record<CreditStatusFilter, { name: string, label: string }> = {
    [CreditStatusFilter.ALL]: { name: 'ทั้งหมด', label: 'ทั้งหมด' },
    [CreditStatusFilter.REQUESTED]: { name: 'รออนุมัติ', label: 'รออนุมัติ' },
    [CreditStatusFilter.APPROVED]: { name: 'อนุมัติแล้ว', label: 'อนุมัติแล้ว' },
    [CreditStatusFilter.REJECTED]: { name: 'ปฏิเสธแล้ว', label: 'ปฏิเสธแล้ว' },
};

const CreditTopUpApprovalPage = ({ }: TopUpRequestPageProps) => {
    const { state } = useAppSettings();
    const { openDialog, closeDialog } = useDialog();
    const [dialogId, setDialogId] = useState<number | null>(null);
    const [filter, setFilter] = useState<CreditRequestParams>(defaultCreditBalanceFilter);
    const [dateRequest, setDateRequest] = useState<string | undefined>(undefined);
    const [dateTransfer, setDateTransfer] = useState<string | undefined>(undefined);
    const [data, setData] = useState<CreditRequestData[]>([]);
    const [page, setPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [requestType, setRequestType] = useState<CreditSourceType | undefined>()
    const { requestTypeParams } = useParams();
    const [isFetch, setIsFetch] = useState<boolean>(false);




    useEffect(() => {
        setRequestType(requestTypeParams === 'top-up' ? CreditSourceType.TOP_UP : requestTypeParams === 'adjustment' ? CreditSourceType.ADJUST : undefined);
        listRequestCredits({ page: 1 });
    }, [filter])

    const listRequestCredits = async ({ page }: CreditRequestParams) => {
        const params = {
            ...filter,
            page: page,
            type: filter.type,
            dateRequest: filter.dateRequest && dayjs(filter.dateRequest).toISOString(),
            dateTransfer: filter.dateTransfer && dayjs(filter.dateTransfer).toISOString(),
        }
        if (!!!params.status || params.status === 'all') delete params.status;

        try {
            const res = await creditService.listCreditRequests(params);
            const metaData = res?.meta ?? { ...defaultCreditBalanceFilter }
            const creditsListResponse = res?.data ?? [];
            const dataWithAdditionalColumns = await Promise.all(creditsListResponse.map(async (item: any) => {
                return {
                    ...item,
                    actions: item.status !== 'REQUEST' ? <Alignment position='center'>
                        <PromptTypo fontWeight='bold' color={`${'primary'}`} variant='subtitle1'>{statusTableDisplayMap[item.status]}</PromptTypo>
                    </Alignment> : _getTopUpRowAction(item),
                    requestDatetime: convertISOStringtoFormattedDateTime(item.requestDatetime),
                    transferDatetime: convertISOStringtoFormattedDateTime(item.transferDatetime),
                    processDatetime: convertISOStringtoFormattedDateTime(item.processDatetime),
                    status: statusTableDisplayMap[item.status],
                    bankAccount: `${item.bankAccountName} (${item.bankAccountBankName})`
                }
            }));

            setPage(res?.meta?.page ?? 1);
            setData(dataWithAdditionalColumns);
            setTotalPages(res?.meta?.totalPages ?? 1);
        } catch (error) {
        }
    };

    const renderStatus = (value: CreditStatusFilter) => {
        return statusDisplayMap[value];
    };


    const handleApprove = async (item: Record<string, any>, approvalStatus: 'APPROVED' | 'REJECTED') => {
        const statusDisplay = approvalStatus == 'APPROVED' ? `<span style="color:green">อนุมัติ</span>` : `<span style="color:red">ปฏิเสธ</span>`
        closeDialog(dialogId);

        const dialogContent = `
        <div style="display: flex; flex-direction: column; text-align: center;">
            <h2 style="font-family: 'Prompt', sans-serif; font-weight: bold;margin:0">${statusDisplay} การเติมเครดิต</h6>
            <h4 style="font-family: 'Prompt', sans-serif;">คุณยืนยันที่จะ${statusDisplay}การเติมเครดิต</h4>
            <h4 style="font-family: 'Prompt', sans-serif;margin:4">
                จำนวน <span style="color: ${state.theme.palette.secondary.main};">${formatToCurrencyString(item.amount)}</span> 
                ให้แก่บัญชี <span style="color: green;">${item.ownerFirstname} ${item.ownerLastname} </span> หรือไม่?
            </h4>
             <textarea id="remark-input-${dialogId}" 
                placeholder="กรุณาใส่เหตุผล (ถ้ามี)" 
                style="
                    margin-top: 10px; 
                    padding: 10px; 
                    font-family: 'Prompt', sans-serif; 
                    font-size: 14px; 
                    width: 100%; 
                    height: 80px; 
                    border: 1px solid #ccc; 
                    border-radius: 5px; 
                    resize: none;" 
            ></textarea>
        </div>
    `;
        DialogService.open(
            {
                html: dialogContent,
                confirmButtonText: 'ตกลง',
                cancelButtonText: 'ยกเลิก',
                confirmButtonColor: state.theme.palette.success.main,
                cancelButtonColor: state.theme.palette.error.main,
                showCancelButton: true,
            },
            async () => {
                const remarkInput = (document.getElementById(
                    `remark-input-${dialogId}`
                ) as HTMLTextAreaElement)?.value || '';

                await creditService.creditApproval(item.id, approvalStatus, remarkInput).then(async (res) => {
                    if (!!res) {
                        const tempPage = JSON.parse(JSON.stringify(page));
                        SwalCompleted().then(() => listRequestCredits({ page: tempPage }))
                    }
                });
            }
        );
    }

    const _getTopUpRowAction = (item: Record<string, any>) => {
        return <AppFloatingSpeedDeal actions={actions(item)} isOpen={true} onToggleOpen={() => { }} />
    }

    const handlePageChange = (newPage: number) => {
        setPage(newPage);
        listRequestCredits({ page: newPage })
    };

    const handleOnFilterChange = async (newValue: CreditRequestFilterOptions) => {
        setFilter(({
            ...filter,
            status: newValue.status ?? CreditStatusFilter.ALL,
            dateRequest: newValue.dateRequest ?? undefined,
            dateTransfer: newValue.dateTransfer ?? undefined,
        }));
    };

    const actions = (selectedIem: any) => {
        return [
            {
                icon: <Close />,
                bgColor: state.theme.palette.error.main,
                name: 'Reject',
                onClick: () => handleApprove(selectedIem, 'REJECTED')
            },
            {
                icon: <Check />,
                bgColor: state.theme.palette.secondary.main,
                name: 'Approve',
                onClick: () => handleApprove(selectedIem,
                    'APPROVED')
            },
        ]
    };
    return (
        <StyledContainer>
            <PromptTypo variant="h5">รายการขอเติมเครดิต</PromptTypo>
            <Spacer size="md" />
            <Stack direction={'row'}>
                <StyledSelect
                    label="สถานะ"
                    value={filter.status ?? CREDIT_STATUS[0].value}
                    options={CREDIT_STATUS}
                    sx={{ minWidth: '170px', marginRight: SPACING_MD }}
                    onChange={(value) => {
                        setFilter({ ...filter, status: value ?? CreditStatusFilter.ALL })
                    }} />

                <SingleDatePicker
                    label="วันที่แจ้ง"
                    onChange={(value) => {
                        setFilter({ ...filter, dateRequest: dayjs(value).format() })
                    }} />
                <Spacer size="md" />
                <SingleDatePicker label="วันที่ฝาก"
                    onChange={(value) => {
                        setFilter({ ...filter, dateTransfer: dayjs(value).format() })
                    }} />
            </Stack>
            <Spacer size='lg' />
            <PaginatedTable
                stickyActions={true}
                stickyHeader={false}
                showPagination={false}
                columns={columns}
                data={data as unknown as DataRow[] || []}
            />
            <AppPagination
                page={page}
                count={+ (totalPages ?? 0)}
                onPageChange={handlePageChange} />
        </StyledContainer >
    );
};


const columns: Column[] = [
    { field: 'id', label: 'เลขที่คำขอ', ...tableContentConfig.common },
    { field: 'ownerFirstname', label: 'ผู้ขอ', ...tableContentConfig.common },
    { field: 'requestDatetime', label: 'วันที่แจ้ง', ...tableContentConfig.common },
    { field: 'transferDatetime', label: 'วันที่ฝาก', ...tableContentConfig.common },
    { field: 'bankAccount', label: 'บัญชี', ...tableContentConfig.common },
    { field: 'amount', label: 'จำนวนเงิน', ...tableContentConfig.number },
    { field: 'requestRemark', label: 'หมายเหตุ', ...tableContentConfig.common },
    { field: 'status', label: 'สถานะ', ...tableContentConfig.commonPrimary },
    { field: 'processDatetime', label: 'ปิดเมื่อ', ...tableContentConfig.common },
    { field: 'adminRemark', label: 'Admin note', ...tableContentConfig.common },
    { field: 'actions', label: '', ...tableContentConfig.action },
];

export default CreditTopUpApprovalPage;

