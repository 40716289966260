import { STORAGE_KEY } from "../configs/constants";
import baseAxios from "./axios_instance";
import { removeEmptyParam } from "./helper";

export interface CreditTxnData {
    id: string;
    ownerFirstname: string;
    ownerLastname: string;
    txDate: string;
    amount: number;
    balance: number;
    remark: string;
    lastUpdateDatetime?: string;
}

export type TopUpRequestBody = {
    ownerId: number;
    type: 'TOPUP' | 'ADJUST'
    bankAccountId: string | number;
    amount: string | number;
    requestDatetime: string;
    transferDatetime: string;
    requestRemark?: string;
}

export enum CreditSourceType {
    TOP_UP = 'TOPUP',
    ADJUST = 'ADJUST',
}

export enum CreditStatusType {
    REQUESTED = 'REQUESTED',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
}

export enum CreditStatusFilter {
    REQUESTED = 'REQUEST',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
    ALL = 'all',
}
export interface TopUpData {
    id: number;
    requestedAt: string;
    createdAt: string;
    targetBankAccount: string;
    amount: number;
    remark: string;
    status: string;
    closed: string;
    adminNote: string;
}

export enum OwnerStatusTypeFilter {
    ALL = 'all',
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    DISABLED = 'DISABLED',
}

export type CreditRequestParams = {
    type?: 'TOPUP' | 'REQUEST' | 'ADJUST';
    status?: CreditStatusFilter | string | null | undefined;
    dateRequest?: string;
    dateTransfer?: string;
    page?: number;
    perPage?: number;
    dateRequestFrom?: Date | string | null | undefined;
    dateRequestTo?: Date | string | null | undefined;
}

export type CreditRequestData = {
    id: number;
    ownerId: number;
    amount: number;
    bankAccountId: number;
    status: string;
    requestDatetime: string;
    transferDatetime: string;
    processDatetime: string;
    processUserId: number;
    requestRemark: string;
    adminRemark: string;
    transactionId: number;
    bankAccountName: string;
    bankAccountBankName: string;
    bankAccountNumber: string;
    balance?: string | number | null
}

export type PaginationMeta = {
    limit?: number;
    page?: number;
    totalCount?: number;
    totalPages?: number;
}

export type ResponseWithMeta<T> = {
    data?: CreditRequestData[] | T[] | null;
    meta?: PaginationMeta
}

export type ApproveOrRejectTopUpRequest = {
    status: string;
    processDatetime: string;
    processUserId: number,
    adminRemark: string;
    transactionId: number,
    transaction: Record<string, any> | null;
}

export type ApproveOrRejectResponse = {
    id: number;
    ownerId: number;
    amount: number;
    bankAccountId: number;
    status: string;
    requestDatetime: string;
    transferDatetime: string;
    processDatetime: string;
    processUserId: number
    requestRemark: string;
    adminRemark: string;
    transactionId: number;
}

export type BankAccountResponse = {
    id: number;
    bankAccountNumber: string | number;
    bankAccountName: string;
    bankName: string;
    minimumAmount?: number
}

export type CreditTransactionRequestOptions = {
    page: string | number;
    perPage: string | number;
    owner?: string | number;
    ownerId?: string | number;
    bankAccountId?: string | number;
}

export type CreditTransactionResponseData = {
    id: string;
    ownerFirstname: string;
    ownerLastname: string;
    txDate: string;
    amount: number;
    balance: number;
    remark: string;
}

export type CreditBalanceData = {
    balance: string;
    id: string;
    ownerId: string;
    lastUpdateDatetime: string;
    ownerFirstname: string;
    ownerLastname: string;
    ownerStatus: string;
}


export type CreditTxnParams = {
    owner?: number;
    page?: number;
    perPage?: number;
    ownerStatus?: string;
}

export type TxnParams = {
    page?: number;
    perPage?: number;
    status: 'ACTIVE' | 'INACTIVE' | 'DISABLED';
}


export type ApproveOrRejectReq = {
    type: "APPROVED" | "REJECTED";
}

class CreditService {
    async getUserToken() {
        return localStorage.getItem(STORAGE_KEY.ACCESS_TOKEN);
    }
    async getHeaders() {
        return { Authorization: `Bearer ${await this.getUserToken()}` }
    }

    async listCreditsBalance(params: CreditTxnParams): Promise<ResponseWithMeta<CreditBalanceData[]> | null> {
        const paramsWithOutEmpty = removeEmptyParam<CreditTxnParams>(params);
        try {
            const response = await baseAxios.get<ResponseWithMeta<CreditBalanceData[]>>("/credits", { params: paramsWithOutEmpty, headers: await this.getHeaders() });
            return response.data;
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    async listCreditTxn(params: CreditTransactionRequestOptions): Promise<ResponseWithMeta<CreditTxnData> | null> {
        const paramsWithOutEmpty = removeEmptyParam<CreditTransactionRequestOptions>(params);
        try {
            const response = await baseAxios.get<ResponseWithMeta<CreditTxnData>>("/credits/txn", { params: paramsWithOutEmpty, headers: await this.getHeaders() });
            return response.data;
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    async creditTopUpRequest(req: TopUpRequestBody): Promise<CreditTxnData | null> {
        console.log('params', req);
        try {
            const response = await baseAxios.post<CreditTxnData>("/credits/requests", req, { headers: await this.getHeaders() });
            return response.data;
        } catch (error) {
            console.log(error);
            return null
        }
    }
    async creditAdjustRequest(req: TopUpRequestBody): Promise<CreditTxnData | null> {
        try {
            const response = await baseAxios.post<CreditTxnData>("/credits/requests", req, { headers: await this.getHeaders() });
            return response.data;
        } catch (error) {
            console.log(error);
            return null
        }
    }
    async listCreditRequests(params: CreditRequestParams): Promise<ResponseWithMeta<CreditRequestData> | null> {
        const paramsWithOutEmpty = removeEmptyParam<CreditRequestParams>({ ...params });
        try {
            const response = await baseAxios.get<ResponseWithMeta<CreditRequestData>>("/credits/requests", { params: paramsWithOutEmpty, headers: await this.getHeaders() });
            return response.data;
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    async listCreditRequestsAdjustment(params: CreditRequestParams): Promise<ResponseWithMeta<CreditRequestData> | null> {
        const paramsWithOutEmpty = removeEmptyParam<CreditRequestParams>({ ...params, type: CreditSourceType.ADJUST });

        try {
            const response = await baseAxios.get<ResponseWithMeta<CreditRequestData>>("/credits/requests", { params: paramsWithOutEmpty, headers: await this.getHeaders() });
            return response.data;
        } catch (error) {
            console.log(error);
            return null;
        }
    }
    async getCreditRequestById(id: number | string) {
        try {
            const response = await baseAxios.get<CreditRequestData>(`/credits/requests/${id}`, { headers: await this.getHeaders() });
            return response.data;
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    async creditApproval(id: string | number, status: 'APPROVED' | 'REJECTED', adminRemark?: string) {
        try {
            const response = await baseAxios.put<CreditRequestData>(`/credits/requests/${id}`, { id, status, adminRemark }, { headers: await this.getHeaders() });
            return response.data;
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    async approveOrRejectTopUpRequest(req: ApproveOrRejectTopUpRequest): Promise<ApproveOrRejectResponse | null> {
        try {
            const response = await baseAxios.put<ApproveOrRejectResponse>(`/credits/requests/{requestId}`, { req }, { headers: await this.getHeaders() });
            return response.data;
        } catch (error) {
            console.log(error);
            return null
        }
    }

    async listCreditTransactions(params: CreditTransactionRequestOptions): Promise<ResponseWithMeta<any[]> | null> {
        try {
            const response = await baseAxios.get<ResponseWithMeta<any[]>>("/credits", { params, headers: await this.getHeaders() });
            return response.data ?? [];
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    async listCreditTxns(params: CreditTransactionRequestOptions) {
        try {
            const response = await baseAxios.get<ResponseWithMeta<CreditTransactionResponseData[]>>("/credits/txn", { params, headers: await this.getHeaders() });
            return response.data ?? [];
        } catch (error) {
            console.log(error);
            return null;
        }
    }


    async listBankAccount(): Promise<BankAccountResponse[] | []> {
        try {
            const response = await baseAxios.get<BankAccountResponse[]>("/banks", { headers: await this.getHeaders() });
            return response.data ?? [];
        } catch (error) {
            console.log(error);
            return [];
        }
    }

    // removeEmptyParamsremoveEmptyParams(params: CreditRequestParams): void {
    //     Object.keys(params).forEach((key) => {
    //         if (!params[key as keyof CreditRequestParams]) {
    //             delete params[key as keyof CreditRequestParams];
    //         }
    //     });
    // }
}
const creditService = new CreditService();
export default creditService;

