// AppPagination.tsx
import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import { SPACING_XL, SPACING_XXL } from '../../configs/constants';


export interface AppPaginationMetaData {
    limit: number;
    page: number;
    totalCount?: number;
    totalPages?: number;

}
interface AppPaginationProps {
    page: number;
    count: number;
    onPageChange?: (value: number) => void;
    label?: string; // Optional label
}

const AppPagination: React.FC<AppPaginationProps> = ({ page, count, onPageChange, label = 'Page' }) => {
    return (
        <Stack sx={{ marginTop: 2, paddingBottom: 16 }} marginTop={SPACING_XL} spacing={2} alignItems="left">
            <Pagination count={count} page={page} onChange={(_, page) => onPageChange?.(page)} />
        </Stack>
    );
};

export default AppPagination;
