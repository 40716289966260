// auth_provider.tsx
import React, { createContext, useState, useEffect, ReactNode } from 'react';
import { authService } from '../../services/auth_service';
import { STORAGE_KEY, User } from "../../configs/constants";
import { UserToken, useUserToken } from '../hooks/user_token';
import userService from '../../services/user_service';
import { Roles } from '../../models/role';
import { useNavigate } from 'react-router-dom';
interface AuthContextType {
    isAuthenticated: boolean;
    login: (username: string, password: string) => Promise<any>;
    logout: () => Promise<void>;
    user: User;
    roles: Roles[]
    setRoles: React.Dispatch<React.SetStateAction<Roles[] | []>>,
    userToken?: UserToken;
    acl: string[];
    randomString?: () => string;
}

export const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
    const context = React.useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [user, setUser] = useState<any>(null);
    const [roles, setRoles] = useState<Roles[] | []>([]);
    const { userToken } = useUserToken();
    const [acl, setAcl] = useState<string[]>([]);

    const login = async (username: string, password: string) => {
        try {
            const response = await authService.login({ username, password });
            if (response === null) {
                return;
            }
            setIsAuthenticated(true);
            setUser(response.user);
            setAcl(response.permissions);
            if (!roles || roles.length == 0)
                await getRoles();
            return { successful: true }
        } catch (error) {
            console.error('Login failed', error);
            alert('Login failed. Please check your credentials.');
            return { successful: false }
        }
    };

    const fetchProfile = async () => {
        try {
            const data = await authService.getMyProfileInfo();
            setUser(data);
        } catch (err) {
            console.error('call fetchProfile failed', err);
        }
    }

    const getRoles = async () => {
        try {
            const data = await userService.getRoles();
            setRoles(data);
        } catch (err) {
            console.error('call setRoles failed', err);
        }
    }

    const logout = async () => {
        try {
            await authService.logout();
        } catch (e) {
            console.warn("Cannot invalidate token from server, just destory in local");
        }
        localStorage.removeItem(STORAGE_KEY.ACCESS_TOKEN);
        localStorage.removeItem(STORAGE_KEY.REFRESH_TOKEN);
        localStorage.removeItem(STORAGE_KEY.USER);
        setIsAuthenticated(false);
        setUser(null);

        window.location.href = '/login';
    };

    const randomString = (): string => {
        // Random String: 6 characters (uppercase, lowercase, and digits)
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let randomString = '';
        for (let i = 0; i < 6; i++) {
            randomString += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        const randomNumber = Math.floor(100000 + Math.random() * 900000);

        return `${randomString}${randomNumber}`;
    };

    useEffect(() => {
        setIsAuthenticated(!!localStorage.getItem(STORAGE_KEY.USER));
        fetchProfile();
    }, []);

    useEffect(() => {
        setIsAuthenticated(!!userToken?.accessToken?.length);
    }, [userToken]);



    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout, user, userToken, acl, roles, setRoles, randomString }}>
            {children}
        </AuthContext.Provider>
    );
};
