import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { hoveredBgColor } from '../configs/constants';

// Extend ButtonProps to include custom properties
interface StyledFillButtonProps extends ButtonProps {
    customColor?: string;
    buttonComponent?: String;
}

// Define the styled component
export const StyledFillButton = styled(Button)<StyledFillButtonProps>(({ theme, customColor }) => ({
    backgroundColor: customColor || theme.palette.action.selected,
    color: '#fefefe',
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
        color: theme.palette.primary.contrastText,
    },
    '&:active': {
        backgroundColor: theme.palette.primary.main,
    },
    '&.Mui-disabled': {
        backgroundColor: '#b3b3b3',
        color: theme.palette.primary.contrastText,
    },
}));

export const StyledOutlineButton = styled(Button)<StyledFillButtonProps>(({ theme, buttonComponent }) => ({
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    border: `2px solid ${theme.palette.primary.main}`,
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
        borderColor: theme.palette.secondary.light,
        color: theme.palette.primary.contrastText,
    },
    '&:active': {
        backgroundColor: theme.palette.action.selected,
        borderColor: theme.palette.primary.main,
    },
    '&.Mui-disabled': {
        borderColor: '#b3b3b3',
        color: '#b3b3b3',
    },
}));


export const StyledTextButton = styled(Button)(({ theme }) => ({
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    border: '0px',
    outline: '0px',
    textTransform: 'none',

    '&:hover': {
        backgroundColor: theme.palette.action.hover,
        color: theme.palette.secondary.light,
    },
    '&:active': {
        backgroundColor: theme.palette.action.selected,
    },
    '&.Mui-disabled': {
        color: '#b3b3b3',
    },
}));

export const StyledOutlineDatepickerButton = styled(Button)(({ theme }) => ({
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.divider}`,
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: hoveredBgColor,
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    '&:active': {
        backgroundColor: theme.palette.action.selected,
        borderColor: theme.palette.primary.main,
    },
    '&.Mui-disabled': {
        borderColor: '#b3b3b3',
        color: '#b3b3b3',
    },
}));
